import Box from "@mui/material/Box";
import OnboardingDone from '../../assets/images/OnboardingDone.png';


const ContentShowcase: React.FC = () => {

    return (
        <Box 
            display="flex" 
            flexDirection="column" 
            alignItems="center" 
            justifyContent="center"  // Centers the inner box vertically
            width="80%" 
            sx={{ height: '90vh', position: 'relative' }}
        >
            {/* Image */}
            <Box 
                component="img" 
                src={OnboardingDone}
                alt="Placeholder"
                width={450}
                height={525}
                sx={{ position: 'relative' }}  // Remove 'sticky' for proper centering
            />
        </Box>
    )
}

export const IntegrationDoneContentShowcase: React.FC = () => {
        return <ContentShowcase />
}