import './App.css';
import { ThemeProvider } from '@mui/material';
import { theme } from './default/theme';

import AppRoutes from './Routes';
import { PostHogProvider } from './context/PostHogContext';

function App() {
    return (
        <div className="App">
            <ThemeProvider theme={theme}>
                <PostHogProvider>
                    <AppRoutes />
                </PostHogProvider>
            </ThemeProvider>
        </div>
    );
}

export default App;
