import Chart from 'react-apexcharts';
import Box from '@mui/material/Box';
import { IChart } from '../../../default/types';
import { ChartTypeEnum } from '../../../enums/charts';


interface ChartProp {
    chart: Partial<IChart>;
}

export const LineChart: React.FC<ChartProp> = ({ chart }) => {

    if(!chart) return(<></>);

    const { type, colors, series, xAxis, yAxis } = chart;
    const options = {
        enableToolbar: false,
        chart: {
            type: ChartTypeEnum.LINE,
        },
        xaxis: xAxis,
        yaxis: {
            ...yAxis,
            tickAmount: 5,
        },
        colors: colors,
        dataLabels: {enabled: true},
    };


    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
        }}>
            <Chart
                options={options}
                type={type}
                series={series}
                width="500"
            />
        </Box>
    );
};
