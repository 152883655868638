import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ReportComponentSprintMetricItem } from "../types";

interface SprintMetricsComponenet {

    metrics: ReportComponentSprintMetricItem[]
}

export const SprintMetricsTable: React.FC<SprintMetricsComponenet> = ({metrics}) => {

    return (
    <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ backgroundColor: '#efeff5', color: 'black' }}>Metric</TableCell>
            <TableCell sx={{ backgroundColor: '#efeff5', color: 'black' }}>Current Sprint</TableCell>
            <TableCell sx={{ backgroundColor: '#efeff5', color: 'black' }}>Last Sprint</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {metrics.map((metric, index) => (
            <TableRow key={index} sx={{ backgroundColor: 'white' }}>
              <TableCell sx={{fontSize: '14px', color: 'grey'}}>{metric.name}</TableCell>
              <TableCell sx={{fontSize: '14px', color: 'grey'}}>{metric.currentSprint}</TableCell>
              <TableCell sx={{fontSize: '14px', color: 'grey'}}>{metric.previousSprint}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}