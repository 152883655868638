// PostHogContext.tsx
import React, { createContext, useContext } from 'react';
import { PostHog } from 'posthog-node'

// Create a context for PostHog
const PostHogContext = createContext<PostHog | undefined>(undefined);

// Custom hook to use PostHog
export const usePostHog = () => {
    const context = useContext(PostHogContext);
    if (context === undefined) {
        throw new Error('usePostHog must be used within a PostHogProvider');
    }
    return context;
};

// Provider component to pass the PostHog client to the app
export const PostHogProvider = ({ children }: { children: React.ReactNode }) => {
    const postHogClient = new PostHog(
        'phc_K9LJDgWIQ3COSgSRj8eJqIi41MZ7EnRuPg291k2DQ86',
        { host: 'https://us.i.posthog.com' }
    );

    return (
        <PostHogContext.Provider value={postHogClient}>
            {children}
        </PostHogContext.Provider>
    );
};
