import React from 'react';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';

import { agentChatCardStyle } from '../../default/theme';
import AgentChatCardHeader from '../AgentChatCardHeader';
import ActionsBar from '../../assets/images/ActionsBar.png'
import { ReportTypography } from '../CustomComponents/ReportTypography';
import { ReportComponenetEnum } from '../../enums';
import { RepositoryAnalysisTable, SprintMetricsTable } from './tables';
import { 
    Report,
    ReportComponenetRepositoryAnalysisItem,
    ReportComponent,
    ReportComponentChartItem,
    ReportComponentContributorItem,
    ReportComponentGoalItem,
    ReportComponentIssueItem,
    ReportComponentPullRequestItem,
    ReportComponentSprintMetricItem,
} from './types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faJira } from '@fortawesome/free-brands-svg-icons';
import { faCodePullRequest } from '@fortawesome/free-solid-svg-icons';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import { LineChart, PieChart } from './charts';
import { styled } from '@mui/system';
import { Chip, Grid, LinearProgress } from '@mui/material';
import { InitiativeStatusEnum } from '../../enums/resources';



interface Props {
  content: any;
  date: string;
}

const listStyles2 = {
  marginLeft:'10px',
  marginBottom: '8px',
}

const reportCardTitle = {
  fontSize: '18px',
  //fontWeight: 'bold',
  color: 'black',
  marginTop: '15px',
  marginBottom: '10px',
  marginLeft:'10px',
  
};

const reportComponentMessage = {
  fontSize: '16px',
  fontWeight: 'bold',
  color: 'grey',
  marginTop: '15px',
  marginBottom: '10px',
  marginLeft:'10px',

};


const renderChartItem: React.FC<ReportComponentChartItem> = (chartItem: ReportComponentChartItem) => {
    if(chartItem.category === 'line'){
        return <LineChart chart={chartItem.chart}/>
    }
    if(chartItem.category === 'pie'){
        return <PieChart chart={chartItem.chart}/>
    }
    return (<></>)
}


const renderContributorItem: React.FC<ReportComponentContributorItem> = (contributor: ReportComponentContributorItem) => {
    
    return(
        <ReportTypography  sx={listStyles2}>
            {}
        </ReportTypography>
    )
}

const renderGoalItem: React.FC<ReportComponentGoalItem> = (item: ReportComponentGoalItem, index: string) =>{

    return(
        <ListItem >
            <ListItemIcon>
                <GpsFixedIcon />
            </ListItemIcon>
            <ListItemText primary={item.name} sx={{minWidth:500}} />
        </ListItem>
    )
}

interface InitiativeItemProp {

    name: string;

    completionRate: number;

    numberOfOpenIssues: number;

    status: InitiativeStatusEnum;
}



const renderInitiativeAtRiskItem: React.FC<InitiativeItemProp> = (initiative: InitiativeItemProp) => {
    
    const renderInitiativeStatus:React.FC<InitiativeStatusEnum> = (status: InitiativeStatusEnum) => {

        if(status === InitiativeStatusEnum.AT_RISK){
            return (
                <Chip label="At Risk" style={{ 
                        backgroundColor: '#e76c0b',
                        fontWeight: 700,
                        color: 'white',
                        right:0,
                    }} 
                />
           )
        }
        return (
                <Chip label="Delayed" style={{ 
                        backgroundColor: '#fa4225',
                        fontWeight: 700,
                        color: 'white',
                        right:0,
                    }} 
                />
           )
        
    }
    
    return (
        <Box sx={{ width: '100%', mt: 2, marginLeft: '25px' }}>
            <Grid container top={0}>
                <Grid item xs={9}>
                    <Typography variant="caption" component="div" sx={{ fontSize: 15 }}>
                        {initiative.name}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    {renderInitiativeStatus(initiative.status)}
                </Grid>
            </Grid>
            
            
        </Box>
    )
}

const renderInitiativeProgressItem: React.FC<InitiativeItemProp> = (initiative: InitiativeItemProp) => {
    const CustomLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
        height: 10,
        borderRadius: 5,
        '& .MuiLinearProgress-bar': {
            borderRadius: 5,
            backgroundColor: '#20d84d',
        },
        backgroundColor: '#e0e0e0', // Background of the track
    }));

    const Tooltip = styled(Box)(({ theme }) => ({
        position: 'absolute',
        transform: 'translateX(-50%)',
        top: '-30px',
        backgroundColor: '#7b1fa2',
        color: 'white',
        padding: '4px 8px',
        borderRadius: '4px',
        fontSize: '12px',
        fontWeight: 'bold',
        '&::after': {
            content: '""',
            position: 'absolute',
            top: '100%',
            left: '50%',
            marginLeft: '-5px',
            borderWidth: '5px',
            borderStyle: 'solid',
            borderColor: '#7b1fa2 transparent transparent transparent',
        },
    }));
    return(<>
        <Box sx={{ width: '90%', mt: 3, position: 'relative', marginLeft: '25px' }}>
            {/* Tooltip for progress percentage */}
            <Tooltip sx={{ left: `${initiative.completionRate * 100}%` }}>
                {Math.round(initiative.completionRate * 100)}%
            </Tooltip>
            {/* Progress bar */}
            <CustomLinearProgress variant="determinate" value={initiative.completionRate * 100} />
            <Typography variant="caption" component="div" sx={{ fontSize: 15 }}>
                {initiative.name}
            </Typography>
        </Box>
    </>)
}

const renderIssueItem: React.FC<ReportComponentIssueItem> = (issue: ReportComponentIssueItem) => {
    

    return(
         <Box display="flex" alignItems="center" sx={{marginLeft:'25px'}}>
            <FontAwesomeIcon icon={faJira} size={'lg'} color={'#6EB7FF'} /> 
            <Typography variant="body1" style={{ marginLeft: '10px', minWidth: '75px' }}>{issue.number}</Typography>
            <Link 
                target="_blank"
                rel="noopener noreferrer"
                href={issue.link}
                style={{ marginLeft: '15px', color: '#0059b3' }}>
                {issue.title}
            </Link>
        </Box>
    )
}

const renderPullRequestItem: React.FC<ReportComponentPullRequestItem> = (pullRequest: ReportComponentPullRequestItem) => {
    
    return(
        <Box display="flex" alignItems="center" sx={{marginLeft:'10px'}}>
            <FontAwesomeIcon icon={faCodePullRequest} size={'lg'} color={'#6EB7FF'} /> 
            <Typography variant="body1" style={{ marginLeft: '10px', minWidth: '75px' }}>{pullRequest.number}</Typography>
            <Link
                target="_blank"
                rel="noopener noreferrer"
                href={pullRequest.link}
                style={{ marginLeft: '15px', color: '#0059b3' }}>
                {pullRequest.title} 
            </Link>
        </Box>
    )
}

const RenderReportComponent: React.FC<ReportComponent> = ({items, message, resource, title}) => {

    switch(resource){

        case ReportComponenetEnum.CONTRIBUTOR:
            if(items && items.length){
                return (
                    <div>
                        <ReportTypography  sx={reportComponentMessage}>
                            {title}
                        </ReportTypography>
                        <ReportTypography>
                                {message}
                            </ReportTypography>
                        {items.map(function(item, index){
                            // Temporary solution
                            return renderContributorItem(item as ReportComponentContributorItem, index)
                        })}
                    </div>
                );  
            };
            return ( 
                <div>
                    <ReportTypography  sx={reportComponentMessage}>
                        {message}
                    </ReportTypography>
                    <ReportTypography  sx={listStyles2}>
                        No Contributors Found
                    </ReportTypography>
                </div>
            );

        case ReportComponenetEnum.REPOSITORY_ANALYSIS:

            return ( 
                <div>
                    <ReportTypography  sx={reportComponentMessage} >
                        {message}
                    </ReportTypography>
                    <RepositoryAnalysisTable repositories={items as ReportComponenetRepositoryAnalysisItem[]}/>
                </div>
            );

        case ReportComponenetEnum.PROJECT_ISSUE:
            if(items && items.length){
                const minimizedItemList = items.length > 5 ? items.slice(0, 5) : items;
                return (
                    <div>
                        <ReportTypography  sx={reportComponentMessage}>
                            {message}
                        </ReportTypography>
                        {minimizedItemList.map(function(item, index){
                            return renderIssueItem(item as ReportComponentIssueItem, index)
                        })}
                    </div>
                );  
            };
            return ( 
                <div>
                    <ReportTypography  sx={reportComponentMessage}>
                        {message}
                    </ReportTypography>
                    <ReportTypography  sx={listStyles2}>
                        No Issues Found
                    </ReportTypography>
                </div>
            );

        case ReportComponenetEnum.PULL_REQUEST:
            if(items && items.length){
                const minimizedItemList = items.length > 5 ? items.slice(0, 5) : items;
                return (
                    <div>
                        <ReportTypography  sx={reportComponentMessage}>
                            {message}
                        </ReportTypography>
                        {minimizedItemList.map(function(item, index){
                            return renderPullRequestItem(item as ReportComponentPullRequestItem, index)
                        })}
                    </div>
                );
            };
            return ( 
                <div>
                    <ReportTypography  sx={reportComponentMessage}>
                        {message}
                    </ReportTypography>
                    <ReportTypography  sx={listStyles2}>
                        No Pull Requests Found
                    </ReportTypography>
                </div>
            );
        
        case ReportComponenetEnum.GOAL:
            if(items && items.length){
                return(
                    <div>
                        <ReportTypography  sx={reportComponentMessage}>
                            {message}
                        </ReportTypography>
                        <List
                            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                            component="nav"
                            aria-labelledby="nested-list-subheader"    
                        >
                        {items.map(function(item, index){
                            return renderGoalItem(item as ReportComponentGoalItem, index);
                        })}
                        
                        </List>
                    </div>
                )
            }
            return(<div></div>);

        case ReportComponenetEnum.CHART:
            console.log('Found Chart ==>>> ', items)
            if(items && items.length){
                return (
                    <div>
                        <ReportTypography  sx={reportComponentMessage}>
                            {title}
                        </ReportTypography>
                        {items.map((item, index) => {
                            return renderChartItem(item as ReportComponentChartItem);
                        })}
                    </div>
                )
            }
            return(<div></div>);
        
        case ReportComponenetEnum.METRIC:
            if(items && items.length){
                return (
                    <div>
                        <ReportTypography  sx={reportComponentMessage}>
                            {title}
                        </ReportTypography>
                        
                        <SprintMetricsTable  metrics={items as ReportComponentSprintMetricItem[]}/>
                    </div>
                )
            }

            return(<div></div>);
        case ReportComponenetEnum.UNPLANNED_WORK:
            if(items && items.length){
                const minimizedItemList = items.length > 5 ? items.slice(0, 5) : items;
                return (
                    <div>
                        <ReportTypography  sx={reportComponentMessage}>
                            {message}
                        </ReportTypography>
                        {minimizedItemList.map(function(item, index){
                            return renderIssueItem(item as ReportComponentIssueItem, index)
                        })}
                    </div>
                );  
            };

            return(<div></div>);
        case ReportComponenetEnum.INITIATIVE:
            if(items && items.length){
                return (
                    <div>
                        <ReportTypography  sx={reportComponentMessage}>
                            {title}
                        </ReportTypography>

                        {items.map(function(item, index){
                            if(title === 'Initiatives At Risk'){
                                return renderInitiativeAtRiskItem(item as InitiativeItemProp, index)
                            }
                            if(title === 'Initiatives With Progress'){
                                return renderInitiativeProgressItem(item as InitiativeItemProp, index)
                            }
                            return(<></>)
                            
                        })}
                    </div>
                ); 
            };

            return(<div></div>);
        default:
            return(<div></div>);
    }
    
}

const AgentReportCard1: React.FC<Props> = ({ content, date }) => {

    const firstName = localStorage.getItem('firstName');

    const report: Report = content.report;

    // Hard coded for now, need to find a better solution to order the report components
    if (report.name === 'Weekly Summary'){
        // Reorder the components 
        let components: ReportComponent[] = []
        components = [...components, ...report.components.filter(component => component.title === 'Weekly Goals')];
        components = [...components, ...report.components.filter(component => component.title === 'Burndown')];
        components = [...components, ...report.components.filter(component => component.title === 'Team Metrics')];
        components = [...components, ...report.components.filter(component => component.title === 'Resource Allocation')];
        components = [...components, ...report.components.filter(component => component.title === 'Unplanned Work')];

        report.components = components;
    }

    const composeReportMessage = (reportName: string): string => {
        if(reportName === 'Morning Daily Standup Report'){
            return ' I created the Daily report so you and the team can focus on what matters';
        }

        if(reportName === 'Evening Daily Summary Report'){
            return 'Here is what got completed today by the team';
        }

        if(reportName === 'Weekly Summary'){
            return ` Let's see what the team accomplished this week `;
        }
        return reportName;

    }

  return ( 
    <Card sx={agentChatCardStyle}>
      <AgentChatCardHeader name='Wingman' date={date} />
      <ReportTypography sx={reportCardTitle}>
        Hi,  {firstName} {composeReportMessage(report.name)}
      </ReportTypography>
      
      { 
        report && report.components.map( function(component){
            return RenderReportComponent({
                items: component.items, 
                message: component.message, 
                resource: component.resource,
                title: component.title,
            })
        })
      }
      <div style={{marginBottom:'50px'}} />
      <ReportTypography>
        <img src={ActionsBar} alt='ActionsBar' />
      </ReportTypography>
    </Card>
  );
}

export default AgentReportCard1;
