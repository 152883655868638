import React, { createContext,ReactNode, useState, useEffect, useContext } from 'react';
import { setToken, removeToken } from '../auth';
import { isTokenValid } from '../auth';

type Props = {
  children: ReactNode;
};

interface AuthContextProps {
    isLoading: boolean;
    isAuthenticated: boolean;
    login: (token: string) => void;
    logout: () => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);
const authChannel = new BroadcastChannel('auth');

export const AuthProvider: React.FC<Props> = ({ children }: Props) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        
        if (isTokenValid()) {
            setIsAuthenticated(true);
        };

        setIsLoading(false);

        authChannel.onmessage = (message) => {
            if (message.data.type === 'login') {
                setIsAuthenticated(true);
            } else if (message.data.type === 'logout') {
                setIsAuthenticated(false);
            }
        };

        //return () => {
        //    authChannel.close();
        //};
    }, []);

  const login = (token: string) => {
    setToken(token);
    setIsAuthenticated(true);
    authChannel.postMessage({ type: 'login' });
  };

  const logout = () => {
    removeToken();
    setIsAuthenticated(false);
    authChannel.postMessage({ type: 'logout' });
  };

  return (
    <AuthContext.Provider value={{ isLoading, isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
