import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { agentChatCardStyle } from '../../default/theme';
import AgentChatCardHeader from '../AgentChatCardHeader';
import ActionsPanel from '../ActionsPanel';

interface Props {
  content: string;
  date: string;
}

const AgentHTMLCard: React.FC<Props> = ({ content, date }) => {
  return (
    <Card sx={agentChatCardStyle}>
      <AgentChatCardHeader name='Wingman' date={date} />
      <Box>
        <div
            dangerouslySetInnerHTML={{ __html: content }} // Use sanitized HTML string
        />
      </Box> 
      <ActionsPanel />
    </Card>
  );
}

export default AgentHTMLCard;
