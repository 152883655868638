export enum ReportComponenetEnum {

    CONTRIBUTOR = 'CONTRIBUTOR',

    GOAL = 'GOAL',

    CHART = 'CHART',

    INITIATIVE = 'INITIATIVE',

    METRIC = 'METRIC',

    RESOURCE_ALLOCATION = 'RESOURCE_ALLOCATION',

    UNPLANNED_WORK = 'UNPLANNED_WORK',

    PULL_REQUEST = 'PULL_REQUEST',

    PROJECT_ISSUE = 'PROJECT_ISSUE',

    REPOSITORY_ANALYSIS = 'REPOSITORY_ANALYSIS',
}
