export enum ConditionOperatorEnum {

    EQUAL = 'EQUAL',

    ELAPSED = 'ELAPSED',

    GREATER = 'GREATER',

    LESSER = 'LESSER',

    NOT_EQUAL = 'NOT_EQUAL',

    RANGE = 'RANGE',

    SINCE = 'SINCE',
}

export enum AlertResourceEnum {

    REPOSITORY = 'REPOSITORY',

    BOARD = 'BOARD',
}
