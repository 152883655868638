
import * as jwtDecode from 'jwt-decode';
import { getToken, removeToken } from './Cookies';

export const isTokenValid = (): boolean => {
    const token = getToken();
    if (!token){
        return false;
    } 

    try {
        const { exp } = jwtDecode.jwtDecode(token);

        if(exp)
        console.log(`Checking is Valid Token. Expiring at ${exp * 1000} and now ${Date.now()}`)
        if (exp && Date.now() >= exp * 1000) {
            removeToken();
            return false;
        }
        return true;
    } catch (e) {
        return false;
    }
};