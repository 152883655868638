import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";


import JiraOnboardingShowcase from '../../assets/images/JiraOnboardingShowcase.png';
import GithubOnboardingShowcase from '../../assets/images/GithubContentShowcase.png';
import SlackOnboardingShowcase from '../../assets/images/SlackContentShowcase.png';

const integrationShowcases = [
    {
        image: JiraOnboardingShowcase,
        size:{width:450, height:500},
        message: 'Jira integration allows Wingman to alert you about high priority tickets, summarize the day for you, show you up-to-date status of projects and more.',
        title: 'How Jira integration helps you',
    },
    {
        image: GithubOnboardingShowcase,
        size:{width:450, height:500},
        message: 'Github integration allows Wingman to report to the impact of PRs or tickets and initiatives, improve review time and give a 360 view of what folks are really working on.',
        title: 'How Github integration helps you',
    },
    {
        image: SlackOnboardingShowcase,
        size:{width: 738, height:505},
        message: 'Slack integration allows Wingman to notify you once an alert was triggered and identify discussions that impacts delivery.',
        title: 'How Slack integration helps you',
    },
]


interface IntegrationContentShowcaseProps {

    image: string;

    message: string;

    size: Record<string, number>;

    title: string;
}

const ContentShowcase: React.FC<IntegrationContentShowcaseProps> = ({image, message, size, title}) => {

    return (
        <Box 
            display="flex" 
            flexDirection="column" 
            alignItems="center" 
            width="80%" 
            sx={{ height: '90vh', position: 'relative', justifyContent: 'space-between' }}
        >
            {/* Title */}
            <Typography 
                variant="h5" 
                component="div"
                fontFamily={'Roboto'} 
                gutterBottom 
                sx={{ position: 'sticky', top: 60, width: '100%', textAlign: 'center' }}
            >
                {title}
            </Typography>

            {/* Image */}
            <Box 
                component="img" 
                src={image}
                alt="Placeholder"
                width={size.width}
                height={size.height}
                sx={{ position: 'sticky' }}
            />
            {/* Text */}
            <Typography variant="body1" align="center" gutterBottom fontFamily={'Roboto'} fontSize={16}>
                {message}
            </Typography>

            {/* Button */}
            <Box 
                display="flex" 
                justifyContent="center" 
                sx={{ position: 'sticky', bottom: 0, p: 2 }}
            >
                <Button variant="contained" color="primary">
                    Contact Us
                </Button>
            </Box>
        </Box>
    )
}

interface IntegrationContentShowcaseProp {

    activeStep: number;
}

export const IntegrationContentShowcase: React.FC<IntegrationContentShowcaseProp> = ({activeStep}) => {

        const {image, message, size, title} = integrationShowcases[activeStep];

        return <ContentShowcase image={image} message={message} size={size} title={title}/>
}