import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../assets/images/Logo.svg';
import Pic1 from '../../assets/images/Pic1.svg';
import Pic2 from '../../assets/images/Pic2.svg';
import Pic3 from '../../assets/images/Pic3.svg';
import Bottom1 from '../../assets/images/Bottom1.svg';
import Bottom2 from '../../assets/images/Bottom2.svg';
import { Box } from '@mui/system';
import { 
    LeftBox,
    SignupMainBox,
    LogoBox,
    RightBox,
    RightMiddleBox,
} from '../../components/CustomComponents/SignupBox';
import { 
    LeftTitleTypography,
    LeftDesTypography,
    EmailTypography,
    LinkEmailTypography,
    RightTypography,
} from '../../components/CustomComponents/SignupTypography';
import Button from '@mui/material/Button';
import { Grid, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import { signin } from '../../services/api';
import { useAuth } from '../../context/AuthContext';
import { useUser } from '../../context/UserContext';


const SignInPage: React.FC = () => {
    const { login } = useAuth();
    const { setUserInformation } = useUser();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [emailError, setEmailError] = useState(false);
 

    const navigate = useNavigate();

    console.log(`Signin Page. User Details`);

   const handleUserSignin = async () => {
    try {
        const response = await signin( email, password );

        if(response && response.authToken) {
            login(response.authToken);

            const { user } = response;
            setUserInformation(user);

            localStorage.setItem('userId', user.id);
            localStorage.setItem('organizationId', user.organizationId);
            localStorage.setItem('firstName', user.firstName);
            localStorage.setItem('lastName', user.lastName);
            navigate('/');
        }else {
            setError(true); 
            setAlertMessage(response ? response.message : 'Signin Failed. Please verify your email and password');
        };
      
    } catch (error) {
      console.error('Failed to register user', error);
    }
  };
  
  const  isValidEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isValidEmail(event.target.value)) {
      setEmailError(true)
      if (event.target.value.length === 0) {
        setEmailError(false);
      }
    } else {
      setEmailError(false);
    }
    setEmail(event.target.value);
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  }

  return (
    <SignupMainBox>
      <LogoBox>
        <img src={Logo} alt="Logo" style={{ height: '4rem' }} />
      </LogoBox>

      <LeftBox>
        <LeftTitleTypography>Meet Wingman!</LeftTitleTypography>
        <LeftDesTypography>Sign in and prepare to be amazed.</LeftDesTypography>
        <Box>
          
          <Grid container>
            <Grid item xs={6}>
              <TextField
                id="user-email"
                label="Email address"
                variant="outlined"
                color="secondary"
                sx={{ width:'80%', input : {color: emailError ? "red" : null}}}
                onChange={handleEmailChange}
              />
              {emailError ? <div style={{marginBottom: 12, color: emailError ? "red" : "black", fontSize: 12, fontFamily: "Inter-Regular" }}>*Invalid Eamil Format</div>: <div style={{marginBottom:12, color: emailError ? "red" : "black", fontSize: 12, fontFamily: "Inter-Regular" }}>&nbsp;</div>}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <TextField
                id="user-password"
                label="Password"
                type="password"
                variant="outlined"
                color="secondary"
                sx={{ marginBottom: '25px' , width:'80%' }}
                onChange={handlePasswordChange}
              />
            </Grid>
          </Grid>
        </Box>
        {error && <Typography component={'div'} color='red' sx={{ marginBottom: '25px' }}>{alertMessage}</Typography>}
        <Button
          sx={{ 
            marginTop: '5px', 
            marginRight: 'auto',
          }}
          variant='contained'
          color='info'
          onClick={handleUserSignin}
        >
          Login
        </Button>
        <EmailTypography>
          Don't have a user?
          <Link to="/onboarding/registration" className='noneunderline'>
            <LinkEmailTypography>
              Sign up here
            </LinkEmailTypography>
          </Link>
        </EmailTypography>
      </LeftBox>
      
      <RightBox>
        <RightTypography sx={{ marginBottom: '0.5rem' }}>
          Your Engineering Manager
        </RightTypography>
        <RightTypography sx={{ marginBottom: '2rem' }}>
          Assistant
        </RightTypography>
        <RightMiddleBox>
          <img src={Pic1} alt="Award 1" className="h-8 w-20" />
          <img src={Pic2} alt="Award 2" className="h-8 w-32"/>
          <img src={Pic3} alt="Award 3" className="h-8 w-20"/>
        </RightMiddleBox>

        <div className="signupBottomImages">
          <div className="leftbox"></div>
          <div className="rightbox"></div>
          <img src={Bottom1} alt="Bottom1" style={{ width:'30vw', marginBottom: '15px'}}/>
          <img src={Bottom2} alt="Bottom2" style={{ width:'30vw', }}/>
        </div>

      </RightBox>
    </SignupMainBox>
  );
};

export default SignInPage;