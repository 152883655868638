import axios, { AxiosResponse } from "axios";
import {
  CommunicationManagementProviders,
  ProjectManagementProviders,
  SoftwareManagementProviders,
} from "../default/enums";
import { Conversation, Message, User } from "../default/types";
import { API_URL } from "../default/config";
import { reportsData } from "./data";
import { Alert } from "../types";

const axiosInstance = axios.create({
  baseURL: API_URL,
});

interface ConversationResponse {
  conversations: Conversation[];
}

interface RegisterOrgResponse {
  organizationId: string;
  organizationName: string;
}

interface CreateOrganizationResponse {
  id: string;
}

interface CreateUserResponse {
  id: string;
  firstName: string;
  lastName: string;
  organizationId: string;
}

interface MessagesReposnse {
  messages: Message[];
  count: number;
}

interface RegisterUserResponse {
  userId: string;
  organizationId: string;
  message: string;
}

interface SigninUserResponse {
    user: User;
    authToken: string;
    message: string;
}

export interface TeamMember {
  githubUserLink: string;
  githubUserId: number;
  githubUsername?: string;
  email: string;
  name?: string;
  atlassianUserId?: string;
  atlassianUserLink?: string;
  atlassianUsername?: string;
  score?: number;
  role: string;
}

export interface GithubMember {
  id: number;
  link: string;
  username: string;
}

export interface MatchTeamMembersResponse {
  atlassianTeamMembers: any;
  count: number;
  githubTeamMembers: GithubMember[];
  matchedTeamMembers: TeamMember[];
}

interface reportDataResponse {
  // The conversationId of the Report
  conversationId: string;
}

export async function createInitialReportDuringOnboarding(organizationId: string, userId: string) {
  try {
    const response: AxiosResponse<reportDataResponse> =
      await axiosInstance.post("/onboarding/report", {
        organizationId,
        userId,
      });

    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
}

export async function matchTeamMembers(organizationId: string, userId: string) {
  try {
    const response: AxiosResponse<MatchTeamMembersResponse> =
      await axiosInstance.post("/onboarding/team/match-team-members", {
        organizationId,
        userId,
      });

    console.debug("Matched Team Data:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
}

export const createTeam = async (
  selectedTeamMembers: TeamMember[],
  organizationId: string,
  userId: string
): Promise<void> => {
  console.debug("Saving Selected Members ", selectedTeamMembers);
  try {
    const response: AxiosResponse<void> = await axiosInstance.post(
      "/onboarding/team/create-team",
      {
        teamMembers: selectedTeamMembers,
        organizationId: organizationId,
        userId: userId,
      }
    );

    console.debug("Matched Team Data:", response.data);
  } catch (error) {
    console.error("Error:", error);
  }
};

export async function registerUser(
  email: string,
  firstName: string,
  lastName: string,
  password: string,
  organizationName: string
) {
  try {
    const response: AxiosResponse<RegisterUserResponse> =
      await axiosInstance.post("/registration", {
        email,
        firstName,
        lastName,
        password,
        organizationName,
      });

    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
}

export async function signin(email: string, password: string) {
  try {
    const response: AxiosResponse<SigninUserResponse> =
      await axiosInstance.post("/auth/signin", {
        email: email,
        password: password,
      });

    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
}

type ComponentStates = Boolean[];
interface ReportsNotificationResponse {
  message: string[];
  error: string;
  statusCode: number;
}

export async function createReportsSchedule(
  componentStates: ComponentStates,
  amhour: number,
  pmhour: number,
  userId: string,
  organizationId: string
) {
  try {
    const CurrentTime = new Date();
    const differenceTime =
      (CurrentTime.getUTCDate() - CurrentTime.getDate()) * 24 +
      (CurrentTime.getUTCHours() - CurrentTime.getHours());
    const utcYear = CurrentTime.getUTCFullYear();
    const utcMonth = CurrentTime.getUTCMonth() + 1;
    const utcAmdate = Math.floor(
      CurrentTime.getUTCDate() + (amhour + differenceTime) / 24
    );
    const utcAmhour = (amhour + differenceTime) % 24;
    const utcAmminute = CurrentTime.getMinutes();
    const utcPmdate = Math.floor(
      CurrentTime.getUTCDate() + (pmhour + 12 + differenceTime) / 24
    );
    const utcPmhour = (pmhour + 12 + differenceTime) % 24;

    const reports = reportsData(
      utcYear,
      utcMonth,
      utcAmdate,
      utcAmhour,
      utcAmminute,
      utcPmdate,
      utcPmhour,
      userId,
      organizationId
    );
   
    const response: AxiosResponse<ReportsNotificationResponse> =
      await axiosInstance.post("/onboarding/schedule-report", {
        reports: reports.reports,
        isOnboarding: reports.isOnboarding,
        organizationId: reports.organizationId,
        userId: reports.userId,
      });

    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
}

interface AlertsNotificationResponse {
  message: string[];
  error: string;
  statusCode: number;
}

export async function saveAlertsScheduleDuringOnBoarding(
  alerts: Alert[],
  organizationId: string,
  userId: string,
) {
  

  console.log("selectedAlerts: ", alerts);

  try {
    const response: AxiosResponse<AlertsNotificationResponse> =
      await axiosInstance.post("/onboarding/schedule-alert", {
        alerts: alerts,
        isOnboarding: true,
        organizationId: organizationId,
        userId: userId,
      });

    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
}

export async function getConversations(
  userId: string
): Promise<Conversation[]> {
  try {
    const response: AxiosResponse<ConversationResponse> =
      await axiosInstance.get(`/conversation`, {
        params: {
          userId,
        },
      });
    return response.data.conversations;
  } catch (error) {
    throw new Error("Failed to fetch conversations");
  }
}

export async function createConversation(
  userId: string,
  name: string
): Promise<Conversation> {
  try {
    const response: AxiosResponse<Conversation> = await axiosInstance.post(
      "/conversation",
      {
        userId,
        name,
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to create conversation");
  }
}

export async function getMessages(
  conversationId: string,
  limit?: number,
  startAt?: number
): Promise<MessagesReposnse> {
  try {
    const response: AxiosResponse<MessagesReposnse> = await axiosInstance.get(
      `/message`,
      {
        params: {
          conversationId,
          limit,
          startAt,
          order: "desc",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch messages");
  }
}

export async function createMessage(
  conversationId: string,
  userId: string,
  content: string,
  author: string = "USER",
  category: string = "TEXT",
  subCategory = "TEXT_PLAIN"
): Promise<Message> {
  try {
    const response: AxiosResponse<Message> = await axiosInstance.post(
      "/message",
      {
        conversationId,
        userId,
        content,
        author,
        category,
        subCategory,
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to create message");
  }
}

export async function getAgentResponse(
  conversationId: string,
  userId: string
): Promise<Message> {
  try {
    const response: AxiosResponse<Message> = await axiosInstance.post(
      "/agent-processing",
      {
        conversationId,
        userId,
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to get agent response");
  }
}

export async function createCommunicationManagementIntegration(
  accessCode: string,
  organizationId: string,
  userId: string,
  provider: CommunicationManagementProviders
) {
  try {
    const response = await axiosInstance.post(
      "/communication-management-integration",
      {
        accessCode,
        organizationId,
        userId,
        provider,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
}

export async function getCommunicationManagementIntegration(
  organizationId: string
) {
  try {
    const response = await axiosInstance.get(
      "/communication-management-integration/",
      {
        params: {
          organizationId,
        },
      }
    );

    console.log("CommunicationManagementIntegration: ", response.data);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
}

export async function createProjectManagementIntegration(
  accessCode: string,
  organizationId: string,
  userId: string,
  provider: ProjectManagementProviders
) {
  console.log(`Create project management integration called !!!`);
  try {
    const response = await axiosInstance.post(
      "/project-management-integration",
      {
        accessCode,
        organizationId,
        userId,
        provider,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating project management integration:", error);
  }
}

export async function getProjectManagementIntegration(organizationId: string) {
  try {
    const response = await axiosInstance.get(
      "/project-management-integration/",
      {
        params: {
          organizationId,
        },
      }
    );
    console.log("ProjectManagementIntegration: ", response.data);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
}

export async function createSoftwareManagementIntegration(
  accessCode: string,
  installationId: number,
  organizationId: string,
  provider: SoftwareManagementProviders
) {
  try {
    const response = await axiosInstance.post(
      "/software-management-integration",
      {
        accessCode,
        organizationId,
        installationId,
        provider,
      }
    );
    console.log("Software: ", response.data);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
}

export async function getSoftwareManagementIntegration(organizationId: string) {
  try {
    const response = await axiosInstance.get(
      "/software-management-integration/",
      {
        params: {
          organizationId,
        },
      }
    );

    console.log("SoftwareManagementIntegration: ", response.data);

    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
}

export async function registerOrganization(name: string) {
  try {
    const response: AxiosResponse<RegisterOrgResponse> =
      await axiosInstance.post("/registration/organization", {
        name,
      });
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
}

export async function getUser(email: string) {
  try {
    const response: AxiosResponse<User[]> = await axiosInstance.get("/user", {
      params: {
        email,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
}

export async function createOrganization(name: string) {
  try {
    const response: AxiosResponse<CreateOrganizationResponse> =
      await axiosInstance.post("/organization", {
        name,
        website: "https://www.example.com",
      });
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
}

export async function createUser(
  email: string,
  organizationId: string,
  firstName: string,
  lastName: string
) {
  try {
    const response: AxiosResponse<CreateUserResponse> =
      await axiosInstance.post("/user", {
        firstName,
        lastName,
        email,
        organizationId,
      });
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
}

export async function syncProjectBoards(organizationId: string) {
  console.log(`Sync project data called !!!`);
  try {
    const response = await axiosInstance.post("/project-data-sync/project", {
      organizationId,
    });
    return response.data;
  } catch (error) {
    console.error("Error Sync project data:", error);
  }
}

export async function syncSoftwareRepositories(
  organizationId: string,
  userId: string
) {
  console.log(`Sync software data called !!!`);
  try {
    const response = await axiosInstance.post(
      "/software-data-sync/repositories",
      {
        organizationId,
        userId,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error Sync software data:", error);
  }
}

export async function syncSoftwarePullRequests(
  organizationId: string,
) {
  console.log(`Sync software pull requests called !!!`);
  try {
    const response = await axiosInstance.post(
      "/software-data-sync/pull-requests",
      {
        organizationId:organizationId,
        status:'open',
        repositoryNames:[]
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error Sync software data:", error);
  }
}

export async function syncProjectIssues(
  organizationId: string,
  userId: string,
) {
  console.log(`Sync software data called !!!`);
  try {
    const response = await axiosInstance.post(
      "/project-data-sync/issues",
      {
        organizationId,
        userId,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error Sync software data:", error);
  }
}
