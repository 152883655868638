import React, { useState } from 'react';
import { 
    Card, 
    CardContent, 
    Typography, 
    Chip, 
    Avatar, 
    Grid, 
    Link, 
    Divider,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
} from '@mui/material';
import { singleIssueCardStyle } from '../../default/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFlag as faRegularFlag,
    faLink,
    faCalendarDay,
    faCodeCompare,
    faComment,
} from '@fortawesome/free-solid-svg-icons';
import { 
    AlertMessage,
    AlertMessageResource,
    IconPresentationProp,
    ResourceContributor,
} from '../AgentDateAlertCard/interfaces';
import { truncateText } from '../../helpers/TextHelper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataResourceEnum, ResourceType } from '../../enums';
import { faGithub, faJira } from '@fortawesome/free-brands-svg-icons';

import smallImage from '../../assets/images/Slack-Small-Icon.png'; 

const ResourceSystemIcon: React.FC<IconPresentationProp> = ({ category }) => {

    switch(category){
        case DataResourceEnum.COMMENT:
            return ( 
                <FontAwesomeIcon
                    icon={faComment}
                    size={'lg'}
                />
            ) 
        case DataResourceEnum.PROJECT_ISSUE:
            return ( 
                <FontAwesomeIcon
                    icon={faJira}
                    size={'lg'}
                />
            ) 
        
        case DataResourceEnum.PULL_REQUEST:
            return (
                <FontAwesomeIcon
                    icon={faGithub}
                    size={'lg'}
                />
            )
        case DataResourceEnum.PULL_REQUEST_REVIEW:
            return (
                <FontAwesomeIcon
                    icon={faCodeCompare}
                    size={'lg'}
                />
            )
        
        case DataResourceEnum.TEAM_CHAT_CONVERSATION:
            return (
                 <Box
                    component="img"
                    src={smallImage}
                    alt="small-image"
                    sx={{
                        width: '21px',
                        height: '21px',
                    }}
                />
            )
        
        
        default:
            return (<div/>)
    };
}

interface AlertResourceListProps {

    resources: Partial<AlertMessageResource>[];
}

interface RelatedInitiativeListProps {

    initiatives: Partial<AlertMessageResource>[] | undefined;
}


const RelatedInitiative: React.FC<RelatedInitiativeListProps> = ({initiatives}) => {

    if(initiatives && initiatives.length){

        const initiative = initiatives[0];

        return (
        
            <Grid container spacing={1} style={{ marginBottom: '8px', marginTop:'8px' }}>
                <Grid item>
                    <FontAwesomeIcon icon={faRegularFlag} style={{ color: 'gray' }} />
                </Grid>
                <Grid item>
                    <Typography 
                        variant="body2"
                    >
                     {initiative.title} 
                    </Typography>  
                </Grid>
            </Grid>
        )
    }
    return (<></>)
}

interface RelatedResourcesTitleProps {

    description: string | undefined;

    link: string | undefined;

    title: string | undefined;
}
const RelatedResourcesTitle: React.FC<RelatedResourcesTitleProps> = ({description, link, title}) => {
     
    let renderedText: string = '';
    if (title){
        renderedText =  title;
    }else if (description){
        renderedText = truncateText(description, 4);
    }

    if(!link){
        return (
            <Typography 
                variant="body1"
                style={{ color: '#0059b3' }}
                padding={0}
            >
                {renderedText}
            </Typography>
        )
    }

    return (
        <Link
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            style={{ color: '#0059b3' }}
            padding={0}
        >
            {renderedText} 
        </Link>
    )

        
}

const RelatedResource: React.FC<AlertResourceListProps> = ({resources}) => {
    if(!resources || !resources.length){
        return (<></>);
    };

    const renderElapsedTime = (date: string | undefined): string => {

        if(!date){
            return '';
        }

        const diffInMs = new Date().getTime() - new Date(date).getTime();

        // Convert milliseconds to hours
        const diffInHours = diffInMs / (1000 * 60 * 60);
        if (diffInHours < 24) {
            return `${Math.floor(diffInHours)} Hours Ago`;
        }

        // Hours in a week
        if(diffInHours < 168){
            return `${Math.floor(diffInHours / 24)} Days Ago`;
        }

        if(diffInHours < 672){
            return `${Math.floor(diffInHours / 168)} Weeks Ago`;
        }

        return `${Math.floor(diffInHours / 672)} Months Ago`;
    }

    const renderResourceNumberPrefix = (category: DataResourceEnum | undefined, number: string | undefined):string => {
        if(!number){
            return '';
        }

        if(category === DataResourceEnum.PULL_REQUEST){
            return `# ${number}`;
        }
        return `${number}`
    }

    return(
        <AccordionDetails sx={{padding:0}}>
            {resources.map((resource, index) => (
            <div>
            <Box display="flex" alignItems="left" sx={{marginTop: index > 0 ? '15px' : '0px'}}>
                <ResourceSystemIcon category={resource.category as DataResourceEnum} />
                <Typography 
                    variant="body1"
                    style={{ 
                        marginLeft: '10px',
                        minWidth: '75px'
                    }}
                >
                    {renderResourceNumberPrefix(resource.category, resource.number)}
                </Typography>
                
                <RelatedResourcesTitle description={resource.description} link={resource.link} title={resource.title} />
            </Box>
                <Typography 
                    variant="body2"
                    fontSize={12}
                    color={'gray'}
                >
                {renderElapsedTime(resource.updatedAt)}
                </Typography>
            </div>
            ))}
        </AccordionDetails>
    
    )
}

const RelatedResources: React.FC<Partial<AlertMessage>> = ({ relatedResources }) => {
    const [accordionExpanded, setExpanded] = useState(false);

    const handleAccordionExpandedChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded);
    };
    if(!relatedResources || !relatedResources.length){
        return (<></>)
    }

    const sortedResourceList = relatedResources.sort(
        (a: Partial<AlertMessageResource>, b: Partial<AlertMessageResource>
        ) => {
            const dateA = a.updatedAt ? new Date(a.updatedAt).getTime() : 0; // Treat undefined as the "earliest"
            const dateB = b.updatedAt ? new Date(b.updatedAt).getTime() : 0;
            return dateB - dateA;
        } 
    );
    
    return (
        <Accordion
            disableGutters
            elevation={0}
            onChange={handleAccordionExpandedChange}
            sx={{
                border: 'none',
                '&:before': {
                    display: 'none',
                },
                '&:after': {
                    display: 'none',
                },
            }}
        >
            <AccordionSummary
                sx={{ padding: 0 }}
                expandIcon={<ExpandMoreIcon sx={{ 
                    color: accordionExpanded ? '#4274ef' : 'orange',
                    fontSize:'40px'
                }}/>}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <Typography color={'gray'}>{'Recent Activity'}</Typography>
            </AccordionSummary>
            <RelatedResource resources={sortedResourceList}/>
      </Accordion>
    )
}

interface ResourceContributorProps {

    contributor: ResourceContributor | undefined;
}
const RelatedContributor: React.FC<ResourceContributorProps> = ({contributor}) => {

    return (
        <Grid item>
            <Grid container alignItems="center">
                <Avatar alt="Alex Turner" src="/path/to/alex-turner.jpg" />
                <Typography variant="body2" style={{ marginLeft: '8px' }}>
                {contributor ? contributor.name : 'Unassigned'}
                </Typography>
            </Grid>
        </Grid>
    );
}
interface EstimatedDueDateProps {

    estimatedDueDate: string | undefined
}

const EstimatedDueDate: React.FC<EstimatedDueDateProps> = ({estimatedDueDate}) => {
    if(!estimatedDueDate){
        return (<></>)
    }
    const dateFromString = new Date(estimatedDueDate);
    const formattedDate = dateFromString.toLocaleDateString('en-US', {
        month: 'short', // Displays short month name (e.g., Jan)
        day: 'numeric', // Displays the day (e.g., 25)
    });
    return (
        <Grid item>
            <Grid container alignItems="center">
                <FontAwesomeIcon icon={faCalendarDay}/>
                <Typography variant="body2" color="textSecondary" style={{ marginLeft: '4px' }}>
                    {formattedDate}
                </Typography>
            </Grid>
        </Grid>
    )
}


interface FooterResourcesProp {

    contributor: ResourceContributor | undefined;

    estimatedDueDate: string | undefined;
}
const SingleIssueCardFooter: React.FC<FooterResourcesProp> = ({contributor, estimatedDueDate}) => {

    return (
        <div>
            {/* Divider between notes and footer */}
            {(contributor || estimatedDueDate) && <Divider style={{ margin: '16px 0' }} />}
            <Grid container justifyContent="space-between" alignItems="center">
                <RelatedContributor contributor={contributor}/>
                <EstimatedDueDate estimatedDueDate={estimatedDueDate}/>
            </Grid>
        </div>
    )
}

interface ResourceLabelProp {

    category: DataResourceEnum;

    type: ResourceType; 
}
const ResourceLabel: React.FC<ResourceLabelProp> = ({category, type}) => {

    if(category === DataResourceEnum.PROJECT_ISSUE){

        switch(type){
            case ResourceType.TASK:
                return(
                    <Grid container spacing={1}>
                        <Grid item>
                            <Chip label="Task" style={{ backgroundColor: '#5d91f2', color: 'white' }} />
                        </Grid>
                    </Grid>
                )
            
            case ResourceType.BUG:
                return(
                    <Grid container spacing={1}>
                        <Grid item>
                            <Chip label="Bug" style={{ backgroundColor: '#ea4922', color: 'white' }} />
                        </Grid>
                    </Grid>
                )
            
            case ResourceType.EPIC:
                return(
                    <Grid container spacing={1}>
                        <Grid item>
                            <Chip label="Epic" style={{ backgroundColor: '#aa22ea', color: 'white' }} />
                        </Grid>
                    </Grid>
                )
        }
        
    }

    return (<></>)
}


const SingleResourceCard: React.FC<Partial<AlertMessage>> = ({resource, relatedInitiatives, relatedResources}) => {
    const [accordionExpanded, setExpanded] = useState(true);

    const handleAccordionExpandedChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded);
    };

    const generateLinkLabel = (category: DataResourceEnum) => {

        switch(category){
            case DataResourceEnum.PROJECT_ISSUE:
                return 'View Issue';
            case DataResourceEnum.PULL_REQUEST:
                return 'View Pull Request';
            
            default:
                return 'View Resource';
        }
    }

    if(!resource){
        return(<></>)
    }

    return (
        <Card elevation={3} sx={singleIssueCardStyle}>
            {/* Top section with Chips */}
            <ResourceLabel category={resource.category as DataResourceEnum} type={resource.type}/>
            <Accordion
                defaultExpanded
                disableGutters
                elevation={0}
                onChange={handleAccordionExpandedChange}
                sx={{
                    border: 'none',
                    '&:before': {
                        display: 'none',
                    },
                    '&:after': {
                        display: 'none',
                    },
                }}
            >
                <AccordionSummary
                    sx={{ padding: 0 }}
                    expandIcon={<ExpandMoreIcon sx={{ 
                        color: accordionExpanded ? '#4274ef' : 'orange',
                        fontSize:'40px'
                    }}/>}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography 
                        color={'#0b1a3e'}
                        variant="body1"
                        fontSize={18}
                    >
                        {`${resource?.number} - ${resource?.title}`}
                    </Typography>
                </AccordionSummary>
                {/* Task title and description */}
                <AccordionDetails sx={{ padding: 0 }}>
                    <CardContent style={{ padding: 0 }}>

                        {/* Initiative */}
                        <RelatedInitiative initiatives={relatedInitiatives}/>

                        {/* Links */}
                        <Grid container spacing={1} style={{ marginBottom: '8px'}}>
                        <Grid item>
                            <FontAwesomeIcon icon={faLink} style={{ color: 'gray' }} />
                        </Grid>
                        <Grid item>
                            <Link
                                target="_blank"
                                rel="noopener noreferrer"
                                href={resource.link}
                                style={{ marginLeft: '15px', color: '#0059b3' }}>
                                {generateLinkLabel(resource.category)} 
                            </Link>
                        </Grid>
                        </Grid>

                        {/* Notes section */}
                        <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                        Description:
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph>
                        {resource?.description ? truncateText(resource.description, 50) : 'Issue has no description'}
                        </Typography>
                        {/* Divider between notes and footer */}
                        <Divider style={{ margin: '16px 0' }} />
                        <RelatedResources relatedResources={relatedResources}/>
                    </CardContent>
                    {/* Footer with avatar and date */}
                    <SingleIssueCardFooter 
                        contributor={resource?.contributor} 
                        estimatedDueDate={resource?.estimatedDueDate}
                    />
                </AccordionDetails>
            </Accordion>
    </Card>
  );
};

export default SingleResourceCard;
