import React, { useState } from "react";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import { CardContent, InputAdornment } from "@mui/material";

interface Props {
  handleSendMessage: (content: string) => void;
}

const ChatInput: React.FC<Props> = ({ handleSendMessage }) => {

      const [content, setContent] = useState("");

    const handleContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setContent(e.target.value);
    };

    const onMessageSend = () => {
        handleSendMessage(content);
        setContent("");
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter" && !e.shiftKey && content.length > 0) {
        onMessageSend();
        }
    };

    return (
        <TextField
            fullWidth
            multiline
            variant="outlined"
            placeholder="What would you like help with ?"
            value={content}
            onChange={handleContentChange}
            onKeyDown={handleKeyDown}
            InputProps={{
                sx: {
                padding: 0, // Remove padding around text
                border: 'none', // No border
                },
                endAdornment: (
                <InputAdornment position="end">
                    <IconButton onClick={onMessageSend}>
                    <SendIcon />
                    </IconButton>
                </InputAdornment>
                ),
            }}
            sx={{
                width: "600px",
                paddingTop:'7px',
                backgroundColor: 'transparent', // Makes it blend with the card background
                maxHeight: '40px', // Optional maxHeight if passed
                border: 'none', // Remove border
                '& fieldset': { border: 'none' }, // Remove default Material-UI border
            }}
        />
    )

}

const UserChatInput: React.FC<Props> = ({ handleSendMessage }) => {

  return (
    <Card sx={{
        position: "sticky",
        bottom: 0,
        zIndex:1000,
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        width: "650px",
        padding: "24px",
        borderRadius: "16px",
        boxShadow: "0px 2px 4px 0px rgba(25, 33, 61, 0.08)",
        border: "1px solid #F0F2F5",
        height: "60px",
        display: 'flex', // Enables flexbox
        justifyContent: "center",  // Centers horizontally
        alignItems: "center",      // Centers vertically
      }}>
      <CardContent>
        {/* Other chat UI components */}
        <div style={{ marginTop: 'auto' }}>
          <ChatInput handleSendMessage={handleSendMessage}/>
        </div>
      </CardContent>
    </Card>
  );
};

export default UserChatInput;
