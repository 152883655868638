import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DoneIcon from '../../assets/images/OnboardingDoneIcon.png';


interface DoneCardProps {
  launchingWingman: () => void; // Function prop
}

const DoneCard: React.FC<DoneCardProps> = ({launchingWingman}) => {

    return (
         <Box display="flex" justifyContent="center" width="100%" >
            <Card sx={{ display: 'flex', flexDirection: 'column', height: '400px', width: '420px' }}>
                <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Box display="flex" flexDirection="column" alignItems="center" sx={{ flexGrow: 1, justifyContent: 'center' }}>
                        {/* Title */}
                            <Typography variant="h5" component="div" gutterBottom fontFamily={'Roboto'}>
                                {'All Done'}
                            </Typography>

                            {/* Jira Icon */}
                            <Box 
                                component="img" 
                                src={DoneIcon}
                                alt="Placeholder"
                                width="88px"
                                height="88px" 
                                sx={{marginTop:'50px'}}
                            />

                            {/* Text */}
                            <Typography 
                                variant="body2" 
                                color="text.secondary" 
                                align="center"
                                fontFamily={'Roboto'}
                                fontSize={16}
                                gutterBottom 
                                sx={{marginTop:'50px'}}>
                                {'Launch Wingman to view important metrics of your Repositories and Boards'}
                            </Typography>
                        </Box>
                        
                    </CardContent>
                    <Box display="flex" justifyContent="center" sx={{ p: 2 }}>
                        <Button
                            variant="contained"
                            onClick={() => launchingWingman()}
                            sx={{
                                color: 'white',
                                backgroundColor: '#2388FF',
                                '&:hover': {
                                    backgroundColor: 'primary.dark'
                                }
                            }}
                            >
                            {'Launch Wingman'}
                        </Button>
                    </Box>
                </Card>
            </Box>
    )
}

interface OnboardingDoneCardProps {
  launchingWingman: () => void; // Function prop
}
export const OnboardingDoneCard: React.FC<OnboardingDoneCardProps> = ({launchingWingman}) => {

        return <DoneCard launchingWingman={launchingWingman}/>
}