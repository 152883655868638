import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { PullRequestSize } from "../../../types";
import { ReportComponenetRepositoryAnalysisItem } from "../types";

interface RepositoryAnalysisComponenet {

    repositories: ReportComponenetRepositoryAnalysisItem[]
}

export const RepositoryAnalysisTable: React.FC<RepositoryAnalysisComponenet> = ({repositories}) => {

    const formatPullRequestSize = (pullRequestSize: PullRequestSize): string => {
        if(!pullRequestSize || !pullRequestSize.files || !pullRequestSize.linesOfCode){
            return 'XXXXX'
        }
        return `Files: ${pullRequestSize.files} and Lines Of Code: ${pullRequestSize.linesOfCode}`;
    }

    const formatPullRequestReviewTime = (averagePullRequestReviewTimeInHours: number): string => {
        if(!averagePullRequestReviewTimeInHours){
            return 'XXXXX'
        }
        return `${averagePullRequestReviewTimeInHours} Hours`;
    }

    return (
    <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ backgroundColor: '#efeff5', color: 'black' }}>Repository</TableCell>
            <TableCell sx={{ backgroundColor: '#efeff5', color: 'black' }}>Average PR Size</TableCell>
            <TableCell sx={{ backgroundColor: '#efeff5', color: 'black' }}>Average Review Time</TableCell>
            <TableCell sx={{ backgroundColor: '#efeff5', color: 'black' }}>Large PRs Without Comments</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {repositories.map((row, index) => (
            <TableRow key={index} sx={{ backgroundColor: 'white' }}>
              <TableCell sx={{fontSize: '14px', color: 'grey'}}>{row.name}</TableCell>
              <TableCell sx={{fontSize: '14px', color: 'grey'}}>{formatPullRequestSize(row.averagePullRequestSize as PullRequestSize)}</TableCell>
              <TableCell sx={{fontSize: '14px', color: 'grey'}}>{formatPullRequestReviewTime(row.averagePullRequestReviewTimeInHours)}</TableCell>
              <TableCell sx={{fontSize: '14px', color: 'grey'}}>{row.numberOfPullRequestsWithoutComments}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}