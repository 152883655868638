import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../assets/images/Logo.svg';
import Pic1 from '../../assets/images/Pic1.svg';
import Pic2 from '../../assets/images/Pic2.svg';
import Pic3 from '../../assets/images/Pic3.svg';
import Bottom1 from '../../assets/images/Bottom1.svg';
import Bottom2 from '../../assets/images/Bottom2.svg';
import { Box } from '@mui/system';
import { LeftBox, SignupMainBox, LogoBox, RightBox, RightMiddleBox } from '../../components/CustomComponents/SignupBox';
import { LeftTitleTypography, LeftDesTypography, EmailTypography, LinkEmailTypography, RightTypography } from '../../components/CustomComponents/SignupTypography';
import Button from '@mui/material/Button';
import { Grid, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import { registerUser, signin } from '../../services/api';
import { useAuth } from '../../context/AuthContext';
import { useUser } from '../../context/UserContext';


const SignUpPage: React.FC = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [organization, setOrganization] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [emailError, setEmailError] = useState(false);
  const { login } = useAuth();
  const { setUserInformation } = useUser();

  const navigate = useNavigate();

   const handleUserRegistration = async () => {
    try {
        const response = await registerUser( email, firstName, lastName,  password, organization);

        if(response?.userId && response.organizationId) {

            const userInformation = await signin(email, password);

            if(!userInformation || !userInformation.authToken){
                // What should be the logic 
                return;
            }
            
            const { user } = userInformation;
            console.log(`User Registered with Token`)
            login(userInformation.authToken);
            setUserInformation({
                id: user.id,
                organizationId: user.organizationId,
                firstName: user.firstName,
                lastName: user.lastName,
            })
            localStorage.setItem('userId', user.id);
            localStorage.setItem('firstName', firstName);
            localStorage.setItem('lastName', lastName);
            localStorage.setItem('organizationId', user.organizationId);

          navigate('/onboarding/connect-integrations');
        }

        else if (response?.userId === null) {
          setError(true); 
          setAlertMessage(response?.message);
        }
      
    } catch (error) {
      console.error('Failed to register user', error);
    }
  };

    const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setFirstName(event.target.value);
    }

    const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setLastName(event.target.value);
    }

    const handleOrganizationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOrganization(event.target.value);
      }
  
    const  isValidEmail = (email: string) => {
      return /\S+@\S+\.\S+/.test(email);
    }
    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!isValidEmail(event.target.value)) {
        setEmailError(true)
        if (event.target.value.length === 0) {
          setEmailError(false);
        }
      } else {
        setEmailError(false);
      }
      setEmail(event.target.value);
    }

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(event.target.value);
    }

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setScreenWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

  return (
    <SignupMainBox>
      <LogoBox>
        <img src={Logo} alt="Logo" style={{ height: '4rem' }} />
      </LogoBox>

      <LeftBox>
        <LeftTitleTypography>Meet Wingman!</LeftTitleTypography>
        <LeftDesTypography>Sign in and prepare to be amazed.</LeftDesTypography>
        <Box>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                id="org-name"
                label="Organization Name"
                variant="outlined"
                color="secondary"
                sx={{ marginBottom: '25px', width:'90%' }}
                onChange={handleOrganizationChange}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <TextField
                id="first-name"
                label="First name"
                variant="outlined"
                color="secondary"
                sx={{ marginBottom: '25px', width:'80%', borderRadius:'10px'}}
                onChange={handleFirstNameChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="last-name"
                label="Last name"
                variant="outlined"
                color="secondary"
                sx={{ marginBottom: '25px', width:'80%' }}
                onChange={handleLastNameChange}
              />
            </Grid>
          </Grid>
          
          <Grid container>
            <Grid item xs={6}>
              <TextField
                id="user-email"
                label="Email address"
                variant="outlined"
                color="secondary"
                sx={{ marginBottom: '10px', width:'80%', input : {color: emailError ? "red" : null}}}
                onChange={handleEmailChange}
              />
              {emailError ? <div style={{marginTop: 0, color: emailError ? "red" : "black", fontSize: 12, fontFamily: "Inter-Regular" }}>*Invalid Eamil Format</div>: null}
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="user-password"
                label="Password"
                type="password"
                variant="outlined"
                color="secondary"
                sx={{ marginBottom: '25px' , width:'80%' }}
                onChange={handlePasswordChange}
              />
            </Grid>
          </Grid>
        </Box>
        {error && <Typography component={'div'} color='red' sx={{ marginBottom: '25px' }}>{alertMessage}</Typography>}
        <Button
          sx={{ 
            marginTop: '5px', 
            marginRight: 'auto',
          }}
          variant='contained'
          color='info'
          onClick={handleUserRegistration}
        >
          Get Started
        </Button>
        <EmailTypography>
          Already Exist?
          <Link to="/onboarding/signin" className='noneunderline'>
            <LinkEmailTypography>
              Sign in with Email
            </LinkEmailTypography>
          </Link>
        </EmailTypography>
      </LeftBox>
      
      <RightBox>
        <RightTypography sx={{ marginBottom: '0.5rem' }}>
          Your Engineering Manager
        </RightTypography>
        <RightTypography sx={{ marginBottom: '2rem' }}>
          Assistant
        </RightTypography>
        <RightMiddleBox>
          <img src={Pic1} alt="Award 1" className="h-8 w-20" />
          <img src={Pic2} alt="Award 2" className="h-8 w-32"/>
          <img src={Pic3} alt="Award 3" className="h-8 w-20"/>
        </RightMiddleBox>

        <div className="signupBottomImages">
          <div className="leftbox"></div>
          <div className="rightbox"></div>
          {screenWidth > 1200 ?(   
              <>
                <img src={Bottom1} alt="Bottom1" style={{ width:'30vw', marginBottom: '15px'}}/>
                <img src={Bottom2} alt="Bottom2" style={{ width:'30vw', }}/>  
              </>
            ):(
              <>
                <img src={Bottom1} alt="Bottom1" style={{ width:'400px', marginBottom: '15px'}}/>
                <img src={Bottom2} alt="Bottom2" style={{ width:'400px', }}/>  
              </>
            )
          }
          {/* <img src={Bottom1} alt="Bottom1" style={{ width:'30vw', marginBottom: '15px'}}/> */}
          {/* <img src={Bottom2} alt="Bottom2" style={{ width:'30vw', }}/> */}
        </div>

      </RightBox>
    </SignupMainBox>
  );
};

export default SignUpPage;