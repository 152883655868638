import Chart from 'react-apexcharts';
import Box from '@mui/material/Box';
import { IChart } from '../../../default/types';
import { ChartTypeEnum } from '../../../enums/charts';


interface ChartProp {
  chart: Partial<IChart>;
}

export const PieChart: React.FC<ChartProp> = ({ chart }) => {

    if(!chart) return(<></>);

    const { labels, series, colors } = chart;
        const options = {
            chart: {
                type: ChartTypeEnum.PIE,
            },
            labels:labels,
            colors: colors,
        };
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
            }}>
                <Chart
                    options={options}
                    series={series}
                    type={ChartTypeEnum.PIE}
                    width="500"
                />
            </Box>

        );
};
