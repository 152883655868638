import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

import { agentChatCardStyle } from '../../default/theme';
import AgentChatCardHeader from '../AgentChatCardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faSync,
    faVolumeUp,
} from '@fortawesome/free-solid-svg-icons';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import SingleResourceCard from '../SingleResourceCard';
import { AlertMessage } from './interfaces';

const DottedLine = styled(Box)({
  borderBottom: '1px dotted gray',
  opacity:'30%',
  width: '100%',
  marginTop:'10px'
});


/*
const resourceTypeBackgroundColor = (type: ResourceType): string => {
        switch(type){

            case ResourceType.BUG:
                return '#f25d66';
            case ResourceType.EPIC:
                return '#ac5df2';
            case ResourceType.TASK:
                return '#5d91f2';
            case ResourceType.STORY:
                return '#68f25d';
            default:
                return '#5d91f2';
        }
    }


const RenderInitiativeInformation: React.FC<Partial<AlertMessageResource>> = ({number, link, category, title, type}) => {

    return (<Grid container>
            <Grid item xs={1}>
                <ResourceIcon category={category as DataResourceEnum} />
            </Grid>
            <Grid item xs={2}>
                <Typography variant="body1" style={{ marginLeft: '10px', minWidth: '75px' }}>{number}</Typography>
            </Grid>
            <Grid item xs={8}>
                <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href={link}
                    style={{ marginLeft: '15px', color: '#0059b3' }}>
                    {title} 
                </Link>
            </Grid>
            <Grid item xs={1}>
                {type && 
                    <Typography
                        sx={{
                            backgroundColor: resourceTypeBackgroundColor(type),
                            color: 'white',
                            fontWeight: '500',
                            borderRadius: '10px',
                            padding: '2px 10px',
                            display: 'inline-block',
                        }}
                        >
                        {type}
                    </Typography>
                }
            </Grid>
        </Grid>)
}
*/
/*
const ResourceInformation: React.FC<AlertMessageResource> = ({ number, link, category, title, type }) => {
    
    return (
        <Grid container>
            <Grid item xs={1}>
                <ResourceIcon category={category} />
            </Grid>
            <Grid item xs={2}>
                <Typography variant="body1" style={{ marginLeft: '10px', minWidth: '75px' }}>{number}</Typography>
            </Grid>
            <Grid item xs={8}>
                <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href={link}
                    style={{ marginLeft: '15px', color: '#0059b3' }}>
                    {title} 
                </Link>
            </Grid>
            <Grid item xs={1}>
                {type && 
                    <Typography
                        sx={{
                            backgroundColor: resourceTypeBackgroundColor(type),
                            color: 'white',
                            fontWeight: '500',
                            borderRadius: '10px',
                            padding: '2px 10px',
                            display: 'inline-block',
                        }}
                        >
                        {type}
                    </Typography>
                }
            </Grid>
        </Grid>
    );
}
*/

const AgentDateAlertCard: React.FC<AlertMessage> = ({ name, resource, date, relatedResources,  relatedInitiatives}) => {

    const [notifyEngineerLoading, setNotifyEngineerLoading] = useState(false)
    const [notifyAutomaticallyLoading, setNotifyAutomaticallyLoading] = useState(false)
    const [alertVisible, setAlertVisible] = useState(false)
    
    const handleNotifyEngineer = () => {
        setNotifyEngineerLoading(true);
    }

    const handleNotifyEngineerAutomatically = () => {
        setNotifyAutomaticallyLoading(true);
    }

    useEffect(() => {

    if(notifyEngineerLoading || notifyAutomaticallyLoading){
        const timer = setTimeout(() => {
            setAlertVisible(true);
            setNotifyEngineerLoading(false)
            setNotifyAutomaticallyLoading(false);
        }, 3000); // Auto close after 3 seconds

        return () => clearTimeout(timer); 
    }

    if (alertVisible) {
      const timer = setTimeout(() => {
        setAlertVisible(false);
      }, 3000); // Auto close after 3 seconds

      return () => clearTimeout(timer); // Cleanup timer if component unmounts or re-renders
    }
  }, [alertVisible, notifyEngineerLoading, notifyAutomaticallyLoading]);


    return (
        <Card sx={agentChatCardStyle}>
            {alertVisible && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        marginTop:'100px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1500, // Make sure it's above other content
                        marginLeft: { sm: '140px', xs: 0 },
                    }}
                    >
                    <Alert 
                        variant="outlined" 
                        severity="success" 
                        sx={{
                            mt: 2,
                            fontSize:20,
                            backgroundColor:'#f0eded',
                            textAlign:'center',
                            width:'800px',
                        }}>
                        I have notified the engineer
                    </Alert>
                </Box>
            )}
            <Box sx={{ display: 'flex'}}>
                <AgentChatCardHeader name='Wingman' date={date} />
            </Box>
            
            <Box sx={{ display: 'flex' }}>
            <Grid container>
                <Grid item xs={6}>
                    <Typography sx={{
                        color: '#636463',
                        fontSize: '16px',
                        fontWeight: '600',
                        lineHeight: '130%',
                    }}>
                    {name}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Box  textAlign={'right'}>
                        <Typography sx={{
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '130%',
                        }}>
                        {resource.metricMessage}
                    </Typography>
                    </Box>
                </Grid>
            </Grid>
                
            </Box>
            <DottedLine/>
            {/*
            <Box sx={{ display: 'flex', marginTop:'20px'}}>
                <ResourceInformation 
                    number={resource.number}
                    comments={resource.comments}
                    description={resource.description}
                    link={resource.link}
                    category={resource.category}
                    title={resource.title}
                    type={resource.type}
                />
            </Box>
            */}
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ marginTop:'20px'}} // full height of viewport
                >
                <Grid item >
                    <SingleResourceCard 
                        resource={resource}
                        relatedResources={relatedResources}
                        relatedInitiatives={relatedInitiatives}
                    />
                </Grid>
            </Grid>
                
            
            
            {/*relatedResources &&
                <Box sx={{marginTop:'15px'}}>
                    <RelatedResources
                        relatedResources={relatedResources}
                    />
                </Box>  
            */}
            
            <Box marginTop={2}>
            <Grid container justifyContent="flex-end">
                <Grid item marginRight={2}>
                    <Button 
                        variant="contained"
                        onClick={handleNotifyEngineer}
                        sx={{
                            fontSize: '0.75rem', // Reduce font size
                            padding: '10px 8px', // Reduce padding
                            minWidth: 'auto', // Adjust minimum width
                            border: 'none', // Removes the border
                            boxShadow: 'none', // Removes the shadow if present
                            color: 'gray',
                            '&:hover': {
                                //border: 'none',     // Removes the border on hover
                                boxShadow: 'none',  // Removes the shadow on hover
                                backgroundColor: 'primary.main', // Optionally control hover background
                                color: 'purple'
                                },
                        }}
                    >
                        {notifyEngineerLoading ? (
                            <Box>
                                <CircularProgress 
                                    size={28} 
                                    sx={{ 
                                        color: 'gray', // Color of the spinner
                                        marginRight: '20px', // Centering spinner horizontally
                                    }} 
                                />
                            </Box>
                            
                        ) : (
                            <>
                            <FontAwesomeIcon 
                                icon={faVolumeUp} 
                                style={{ fontSize: '1.25rem', marginRight: '5px' }} 
                            />
                            Nudge Engineer
                            </>
                        )}
                    </Button>
                </Grid>
                <Grid item >
                    <Button 
                        variant="contained"
                        onClick={handleNotifyEngineerAutomatically}
                        sx={{
                            fontSize: '0.75rem', // Reduce font size
                            padding: '10px 8px', // Reduce padding
                            minWidth: 'auto', // Adjust minimum width
                            border: 'none', // Removes the border
                            boxShadow: 'none', // Removes the shadow if present
                            color: 'gray',
                            '&:hover': {
                                //border: 'none',     // Removes the border on hover
                                boxShadow: 'none',  // Removes the shadow on hover
                                backgroundColor: 'primary.main', // Optionally control hover background
                                color: 'purple'
                                },
                        }}
                    >
                        {notifyAutomaticallyLoading ? (
                            <Box sx={{width: '175px'}}>
                                <CircularProgress 
                                    size={28} 
                                    sx={{ 
                                        color: 'gray', // Color of the spinner
                                        marginRight: '20px', // Centering spinner horizontally
                                    }} 
                                />
                            </Box>
                        ) : (
                            <>
                            <FontAwesomeIcon 
                                icon={faSync} 
                                style={{ fontSize: '1.25rem', marginRight: '5px' }} 
                            />
                            Notify Automatically
                            </>
                        )}
                    </Button>
                </Grid>
            </Grid>
            </Box>
        </Card>
    );
}

export default AgentDateAlertCard;
