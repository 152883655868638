export enum ChartTypeEnum {

    BAR = 'bar',
    LINE = 'line',
    AREA = 'area',
    PIE = 'pie',
    DONUT = 'donut',
    RADIAL_BAR = 'radialBar',
    SCATTER = 'scatter',
    BUBBLE = 'bubble',
    HEAT_MAP = 'heatmap',
    CANDLE_STICK = 'candlestick',
    BOX_PLOT = 'boxPlot',
    RADAR = 'radar',
    POLAR_AREA = 'polarArea',
    RANGE_BAR = 'rangeBar',
    RANGE_AREA = 'rangeArea',
    TREE_MAP = 'treemap',
}