export enum DataResourceEnum {
    COMMENT = 'COMMENT',

    PROJECT_ISSUE = 'PROJECT_ISSUE',

    PULL_REQUEST = 'PULL_REQUEST',

    PULL_REQUEST_REVIEW = 'PULL_REQUEST_REVIEW',

    TEAM_CHAT_CONVERSATION = 'TEAM_CHAT_CONVERSATION',
}

export enum ResourceType {

    BUG = 'Bug',

    EPIC = 'Epic',

    STORY = 'Story',

    TASK = 'Task',
}