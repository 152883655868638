import { useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

import { 
  createProjectManagementIntegration, 
  createCommunicationManagementIntegration, 
  createSoftwareManagementIntegration,
} from '../../services/api';
import { 
  CommunicationManagementProviders,
  ProjectManagementProviders,
  SoftwareManagementProviders
} from '../../default/enums';
import { useUser } from '../../context/UserContext';
import { emptyPageLoaderStyle } from '../../default/theme';
import { useAuth } from '../../context/AuthContext';

const IntegrationCallbackPage = () => {
    const { search, pathname } = useLocation();
    const navigate = useNavigate();
    const { user, removeUserInformation } = useUser();
    const { logout } = useAuth();


    const createIntegration = useCallback( async() => {
        const searchParams = new URLSearchParams(search);
        const code = searchParams.get('code');
        
        try {
            
            if (!user){
                logout();
                removeUserInformation();
                navigate('/auth/signin');
                return;
            }

        if (pathname === '/jira-login-callback') {

            console.log('Creating Jira Integration with ', code, ' and org ', user.organizationId ,'  and user :', user.id)
            
            await createProjectManagementIntegration(
                code as string, 
                user.organizationId, 
                user.id, 
                ProjectManagementProviders.JIRA
            );
            if (window.opener) {
                // Send a message to the main window
                window.opener.postMessage(
                    { 
                        context: 'jira-integration-callback',
                        activeStep: 0
                    }, '*');        
                window.close();
            };

        } else if (pathname === '/slack') {

            await createCommunicationManagementIntegration(
                code as string,
                user.organizationId,
                user.id,
                CommunicationManagementProviders.SLACK,
            );

            if (window.opener) {
                // Send a message to the main window
                window.opener.postMessage(
                    { 
                        context: 'slack-integration-callback',
                        activeStep: 2
                    }, '*');        
                window.close();
            };
            
        } else if (pathname === '/github-callback') {

            const installationId = searchParams.get('installation_id');
            await createSoftwareManagementIntegration(
                code as string, 
                Number(installationId) as number, 
                user.organizationId, 
                SoftwareManagementProviders.GITHUB,
            );
            if (window.opener) {
                // Send a message to the main window
                window.opener.postMessage(
                    { 
                        context: 'github-integration-callback',
                        activeStep: 1
                    }, '*');        
                window.close();
            };
        } 
        
        } catch (error) {
            console.error('Create callback error:', error);
        }

        
        
    }, [search, pathname, navigate, user, logout, removeUserInformation]);

    useEffect(() => {
 
            createIntegration();
    }, [createIntegration]);

  return (
    <CircularProgress sx={emptyPageLoaderStyle} />
  );
}

export default IntegrationCallbackPage;