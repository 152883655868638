import Box from '@mui/material/Box';

const Loader = () => {
    return (
        <Box sx= {{
            width: "500px",
            height: "270px",
            }}>
                <svg width="100%" height="100%">
                    <defs>
                        <mask
                        id="mask"
                        maskUnits="userSpaceOnUse"
                        maskContentUnits="userSpaceOnUse"
                        >
                        <linearGradient
                            id="linearGradient"
                            gradientUnits="objectBoundingBox"
                            x2="0"
                            y2="1"
                        >
                            <stop stop-color="white" stop-opacity="0" offset="0%" />
                            <stop stop-color="white" stop-opacity="1" offset="30%" />
                            <stop stop-color="white" stop-opacity="1" offset="70%" />
                            <stop stop-color="white" stop-opacity="0" offset="100%" />
                        </linearGradient>
                        <rect width="100%" height="100%" fill="url(#linearGradient)" />
                        </mask>
                        <linearGradient id="textGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" stopColor="#565A97" />
                            <stop offset="100%" stopColor="#FF7BAC" />
                        </linearGradient>
                        <clipPath id="clipPath">
                            <rect width="30" height="30" fill="white"/>
                        </clipPath>
                    </defs>
                    <g width="100%" height="100%" style={{ mask: "url(#mask)"}}>
                        <g id="phrases" opacity={0}>
                            <g>
                                <g clip-path="url(#clipPath)">
                                    <path id="outlineColor-1" fill-rule="evenodd" clip-rule="evenodd" d="M0 15C0 11.0218 1.58035 7.20644 4.3934 4.3934C7.20644 1.58035 11.0218 0 15 0C18.9782 0 22.7936 1.58035 25.6066 4.3934C28.4196 7.20644 30 11.0218 30 15C30 18.9782 28.4196 22.7936 25.6066 25.6066C22.7936 28.4196 18.9782 30 15 30C11.0218 30 7.20644 28.4196 4.3934 25.6066C1.58035 22.7936 0 18.9782 0 15H0Z" fill="#565A97"/>
                                    <path id="insideColor-1" fill-rule="evenodd" clip-rule="evenodd" d="M27.8569 15.0017C27.8569 13.3133 27.5243 11.6414 26.8782 10.0815C26.232 8.52156 25.285 7.1042 24.0911 5.9103C22.8972 4.71641 21.4798 3.76936 19.9199 3.12322C18.36 2.47709 16.6881 2.14453 14.9997 2.14453C13.3113 2.14453 11.6394 2.47709 10.0795 3.12322C8.51961 3.76936 7.10224 4.71641 5.90835 5.9103C4.71445 7.1042 3.7674 8.52156 3.12127 10.0815C2.47514 11.6414 2.14258 13.3133 2.14258 15.0017C2.14258 18.4116 3.49717 21.6819 5.90835 24.093C8.31953 26.5042 11.5898 27.8588 14.9997 27.8588C18.4096 27.8588 21.6799 26.5042 24.0911 24.093C26.5023 21.6819 27.8569 18.4116 27.8569 15.0017Z" fill="white"/>
                                    <path id="checkColor-1" fill-rule="evenodd" clip-rule="evenodd" d="M13.4531 16.4928L10.811 13.8507C10.7523 13.791 10.6824 13.7436 10.6054 13.7111C10.5283 13.6786 10.4455 13.6617 10.3619 13.6613C10.2782 13.6609 10.1953 13.677 10.118 13.7088C10.0406 13.7406 9.97024 13.7873 9.911 13.8464L8.59743 15.1621C8.47918 15.2823 8.41327 15.4444 8.41407 15.613C8.41487 15.7816 8.48233 15.943 8.60172 16.0621L12.6946 20.1528C12.8937 20.3525 13.1636 20.4656 13.4456 20.4676C13.7276 20.4696 13.999 20.3604 14.201 20.1635L14.7689 19.5957L21.4224 12.9421C21.5345 12.823 21.5953 12.6646 21.5917 12.501C21.5881 12.3375 21.5204 12.1819 21.4031 12.0678L20.0874 10.7521C19.9736 10.6343 19.818 10.5663 19.6543 10.5627C19.4905 10.559 19.332 10.6202 19.2131 10.7328L13.4531 16.4928Z" fill="#565A97"/>
                                </g>
                                <text x="50" y="25" fill="url(#textGradient)" style={{ fontWeight: 400, fontSize: 30, fontFamily: "Mulish-Regular" }}>Celebrating moments...</text>
                            </g>
                            <g transform="translate(0, 107)">
                                <g clip-path="url(#clipPath)">
                                    <path id="outlineColor-2" fill-rule="evenodd" clip-rule="evenodd" d="M0 15C0 11.0218 1.58035 7.20644 4.3934 4.3934C7.20644 1.58035 11.0218 0 15 0C18.9782 0 22.7936 1.58035 25.6066 4.3934C28.4196 7.20644 30 11.0218 30 15C30 18.9782 28.4196 22.7936 25.6066 25.6066C22.7936 28.4196 18.9782 30 15 30C11.0218 30 7.20644 28.4196 4.3934 25.6066C1.58035 22.7936 0 18.9782 0 15H0Z" fill="white"/>
                                    <path id="insideColor-2" fill-rule="evenodd" clip-rule="evenodd" d="M27.8569 15.0017C27.8569 13.3133 27.5243 11.6414 26.8782 10.0815C26.232 8.52156 25.285 7.1042 24.0911 5.9103C22.8972 4.71641 21.4798 3.76936 19.9199 3.12322C18.36 2.47709 16.6881 2.14453 14.9997 2.14453C13.3113 2.14453 11.6394 2.47709 10.0795 3.12322C8.51961 3.76936 7.10224 4.71641 5.90835 5.9103C4.71445 7.1042 3.7674 8.52156 3.12127 10.0815C2.47514 11.6414 2.14258 13.3133 2.14258 15.0017C2.14258 18.4116 3.49717 21.6819 5.90835 24.093C8.31953 26.5042 11.5898 27.8588 14.9997 27.8588C18.4096 27.8588 21.6799 26.5042 24.0911 24.093C26.5023 21.6819 27.8569 18.4116 27.8569 15.0017Z" fill="#565A97"/>
                                    <path id="checkColor-2" fill-rule="evenodd" clip-rule="evenodd" d="M13.4531 16.4928L10.811 13.8507C10.7523 13.791 10.6824 13.7436 10.6054 13.7111C10.5283 13.6786 10.4455 13.6617 10.3619 13.6613C10.2782 13.6609 10.1953 13.677 10.118 13.7088C10.0406 13.7406 9.97024 13.7873 9.911 13.8464L8.59743 15.1621C8.47918 15.2823 8.41327 15.4444 8.41407 15.613C8.41487 15.7816 8.48233 15.943 8.60172 16.0621L12.6946 20.1528C12.8937 20.3525 13.1636 20.4656 13.4456 20.4676C13.7276 20.4696 13.999 20.3604 14.201 20.1635L14.7689 19.5957L21.4224 12.9421C21.5345 12.823 21.5953 12.6646 21.5917 12.501C21.5881 12.3375 21.5204 12.1819 21.4031 12.0678L20.0874 10.7521C19.9736 10.6343 19.818 10.5663 19.6543 10.5627C19.4905 10.559 19.332 10.6202 19.2131 10.7328L13.4531 16.4928Z" fill="white"/>
                                </g>
                                <text x="50" y="25" fill="url(#textGradient)" style={{ fontWeight: 400, fontSize: 30, fontFamily: "Mulish-Regular" }}>Cracking Jokes...</text>
                            </g>
                            <g transform="translate(0, 214)">
                                <g clip-path="url(#clipPath)">
                                    <path id="outlineColor-3" fill-rule="evenodd" clip-rule="evenodd" d="M0 15C0 11.0218 1.58035 7.20644 4.3934 4.3934C7.20644 1.58035 11.0218 0 15 0C18.9782 0 22.7936 1.58035 25.6066 4.3934C28.4196 7.20644 30 11.0218 30 15C30 18.9782 28.4196 22.7936 25.6066 25.6066C22.7936 28.4196 18.9782 30 15 30C11.0218 30 7.20644 28.4196 4.3934 25.6066C1.58035 22.7936 0 18.9782 0 15H0Z" fill="white"/>
                                    <path id="insideColor-3" fill-rule="evenodd" clip-rule="evenodd" d="M27.8569 15.0017C27.8569 13.3133 27.5243 11.6414 26.8782 10.0815C26.232 8.52156 25.285 7.1042 24.0911 5.9103C22.8972 4.71641 21.4798 3.76936 19.9199 3.12322C18.36 2.47709 16.6881 2.14453 14.9997 2.14453C13.3113 2.14453 11.6394 2.47709 10.0795 3.12322C8.51961 3.76936 7.10224 4.71641 5.90835 5.9103C4.71445 7.1042 3.7674 8.52156 3.12127 10.0815C2.47514 11.6414 2.14258 13.3133 2.14258 15.0017C2.14258 18.4116 3.49717 21.6819 5.90835 24.093C8.31953 26.5042 11.5898 27.8588 14.9997 27.8588C18.4096 27.8588 21.6799 26.5042 24.0911 24.093C26.5023 21.6819 27.8569 18.4116 27.8569 15.0017Z" fill="#565A97"/>
                                    <path id="checkColor-3" fill-rule="evenodd" clip-rule="evenodd" d="M13.4531 16.4928L10.811 13.8507C10.7523 13.791 10.6824 13.7436 10.6054 13.7111C10.5283 13.6786 10.4455 13.6617 10.3619 13.6613C10.2782 13.6609 10.1953 13.677 10.118 13.7088C10.0406 13.7406 9.97024 13.7873 9.911 13.8464L8.59743 15.1621C8.47918 15.2823 8.41327 15.4444 8.41407 15.613C8.41487 15.7816 8.48233 15.943 8.60172 16.0621L12.6946 20.1528C12.8937 20.3525 13.1636 20.4656 13.4456 20.4676C13.7276 20.4696 13.999 20.3604 14.201 20.1635L14.7689 19.5957L21.4224 12.9421C21.5345 12.823 21.5953 12.6646 21.5917 12.501C21.5881 12.3375 21.5204 12.1819 21.4031 12.0678L20.0874 10.7521C19.9736 10.6343 19.818 10.5663 19.6543 10.5627C19.4905 10.559 19.332 10.6202 19.2131 10.7328L13.4531 16.4928Z" fill="white"/>
                                </g>
                                <text x="50" y="25" fill="url(#textGradient)" style={{ fontWeight: 400, fontSize: 30, fontFamily: "Mulish-Regular" }}>Celebrating moments...</text>
                            </g>
                        </g>
                    </g>
                    </svg>
                </Box>
    )
}

export default Loader;
