import SvgIcon from '@mui/material/SvgIcon';

export default function EventAlertAvatar() {
  return (
    <SvgIcon sx={{ width: '42px', height: '42px', borderRadius: '24px' }}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Line Rounded/Alert">
      <rect width="32" height="32" fill="#F6FAFF"/>
      <g id="Element">
      <path d="M15.8082 19.5107C15.0626 19.5107 14.4582 20.1151 14.4582 20.8607C14.4582 21.6062 15.0626 22.2107 15.8082 22.2107H15.8262C16.5718 22.2107 17.1762 21.6062 17.1762 20.8607C17.1762 20.1151 16.5718 19.5107 15.8262 19.5107H15.8082Z" fill="#64A7FF"/>
      <path d="M17.0082 9.61069C17.0082 8.94795 16.4709 8.41069 15.8082 8.41069C15.1455 8.41069 14.6082 8.94795 14.6082 9.61069V15.9107C14.6082 16.5734 15.1455 17.1107 15.8082 17.1107C16.4709 17.1107 17.0082 16.5734 17.0082 15.9107V9.61069Z" fill="#64A7FF"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9796 2.64657L2.99406 11.6321C0.879585 13.7466 0.879585 17.1748 2.99406 19.2893L11.9796 28.2749C14.094 30.3893 17.5223 30.3893 19.6368 28.2749L28.6224 19.2893C30.7368 17.1748 30.7368 13.7466 28.6224 11.6321L19.6368 2.64657C17.5223 0.5321 14.094 0.5321 11.9796 2.64657ZM4.90324 13.5414L13.8888 4.55576C14.9489 3.4957 16.6675 3.4957 17.7275 4.55576L26.7131 13.5414C27.7731 14.6014 27.7731 16.32 26.7131 17.38L17.7275 26.3656C16.6675 27.4256 14.9489 27.4256 13.8888 26.3656L4.90324 17.38C3.84319 16.32 3.84319 14.6014 4.90324 13.5414Z" fill="#64A7FF"/>
      </g>
      </g>
      </svg>
    </SvgIcon>
  );
}
