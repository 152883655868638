import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import JiraIcon from '../../assets/images/JiraThreeArrowIcon.png';
import GithubIcon from '../../assets/images/GithubIcon.png';
import SlackIcon from '../../assets/images/SlackColorIcon.png';

const integrationCards = [
    {
        icon: JiraIcon,
        message: 'Jira integration allows Wingman to monitor tickets, epics, sprints, status changes and more.',
    },
    {
        icon: GithubIcon,
        message: 'Github integration allows Wingman to report on PRs, comments and contributors. Wingman does not access your source code.',
    },
    {
        icon: SlackIcon,
        message: 'Slack integration allows Wingman to notify you once an alert was triggered or a report is ready.',
    }
]

interface IntegrationCardProps {
    activeStep: number

    icon: string;

    isConnected: boolean;

    integrationLink: string;

    message: string;
}
const SingleIntegrationCard: React.FC<IntegrationCardProps> = ({activeStep, icon, isConnected, integrationLink, message}) => {

    const onConnectClick = () => {
        const width = 600;
        const height = 700;
        const left = (window.screen.width - width) / 2;
        const top = (window.screen.height - height) / 2;

        // Opens a popup window centered on the screen
        window.open(
            integrationLink,
            'IntegrationPopup',
            `width=${width},height=${height},top=${top},left=${left}`
        );
    }

    return (
         <Box display="flex" justifyContent="center" width="100%" >
            <Card sx={{ display: 'flex', flexDirection: 'column', height: '400px', width: '420px' }}>
                <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Box display="flex" flexDirection="column" alignItems="center" sx={{ flexGrow: 1, justifyContent: 'center' }}>
                        {/* Title */}
                            <Typography variant="h5" component="div" gutterBottom fontFamily={'Roboto'}>
                                {`Integration ${activeStep + 1} of 3`}
                            </Typography>

                            {/* Jira Icon */}
                            <Box 
                                component="img" 
                                src={icon}
                                alt="Placeholder"
                                width="88px"
                                height="88px" 
                                sx={{marginTop:'50px'}}
                            />

                            {/* Text */}
                            <Typography 
                                variant="body2" 
                                color="text.secondary"
                                fontFamily={'Roboto'}
                                fontSize={16} 
                                align="center" 
                                gutterBottom 
                                sx={{marginTop:'50px'}}>
                                {message}
                            </Typography>
                        </Box>
                        
                    </CardContent>
                    <Box display="flex" justifyContent="center" sx={{ p: 2 }}>
                        <Button
                            variant="contained"
                            onClick={onConnectClick}
                            disabled={isConnected} // Toggle the state when the button is clicked
                            sx={{
                                color: isConnected ? 'black' : 'white',
                                backgroundColor: isConnected ? 'white' : '#2388FF',
                                '&:hover': {
                                    backgroundColor: 'primary.dark'
                                }
                            }}
                            >
                            {isConnected ? 'Connected' : 'Connect'}
                        </Button>
                    </Box>
                </Card>
            </Box>
    )
}
interface IntegrationCardProp {

    activeStep: number;

    integrationLink: string;

    isConnected: boolean;
}

export const IntegrationCard: React.FC<IntegrationCardProp> = ({activeStep, integrationLink, isConnected}) => {

        const { icon, message } = integrationCards[activeStep]
        return <SingleIntegrationCard 
            activeStep={activeStep}
            icon={icon}
            isConnected={isConnected}
            integrationLink={integrationLink} 
            message={message}
        />
}