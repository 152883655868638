import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircleDot } from '@fortawesome/free-solid-svg-icons';
import Typography from '@mui/material/Typography';


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 28,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg, #4CAF50 0%, #4CAF50 50%, #4CAF50 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg, #4CAF50 0%, #4CAF50 50%, #4CAF50 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 3,
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.grey[800],
    }),
  },
}));
/*
const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean};
}>(({ theme }) => ({
  backgroundColor: '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  ...theme.applyStyles('dark', {
    backgroundColor: theme.palette.grey[700],
  }),
  variants: [
    {
      props: ({ ownerState }) => ownerState.completed,
      style: {
        backgroundImage:
          'linear-gradient( 360deg, #20d84d 0%, #20d84d 50%, #20d84d 100%)',
      },
    },
  ],
}));
*/
const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean };
}>(({ theme, ownerState }) => ({
    backgroundColor: ownerState.completed ? '#F9FFF9' : '#F2F2F2',
    zIndex: 1,
    color: ownerState.completed ? '#4CAF50' : '#000',
    width: 60,
    height: 60,
    display: 'flex',
    borderRadius: '20%', // Square with slight rounded corners
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    //border: ownerState.completed ? '0.1px solid #F9FFF9' : '0.1px solid #F2F2F2',
    position: 'relative',
    boxShadow: ownerState.completed
        ? '0 0 5px #4CAF50' // Subtle glow for completed steps
        : 'none',
}));

interface CustomStepIconProps {

  active?: boolean;
  completed?: boolean;
  integrationName: string;
  stepNumber: number;
}

const  CustomStepIcon: React.FC<CustomStepIconProps> = ({active, completed, integrationName, stepNumber}) => {

  return (
    <ColorlibStepIconRoot 
        ownerState={{ completed }} 
    >
      {completed ? (
        <FontAwesomeIcon icon={faCheckCircle} style={{ color: '#4CAF50' }} />
        ) : (
        <FontAwesomeIcon icon={faCircleDot} style={{ color: '#000' }} />
        )}
        <Typography variant="body2" style={{ marginTop: '5px' }}>
        {integrationName}
        </Typography>
    </ColorlibStepIconRoot>
  );
}

const steps = ['Jira', 'Github', 'Slack'];


interface IntegrationStepperProps {

    activeStep: number;

    integrationConnectionStatus: boolean[]
}
export const CustomizedSteppers: React.FC<IntegrationStepperProps> = ({activeStep, integrationConnectionStatus}) => {
  return (
    <Stack sx={{ width: '100%', paddingTop: '100px' }} spacing={2}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel StepIconComponent={(props) => (
                <CustomStepIcon 
                    completed={integrationConnectionStatus[index]}
                    integrationName={label}
                    stepNumber={index + 1} 
                />
            )}>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
