import { AlertResourceEnum, ConditionOperatorEnum } from "../enums";
import { Alert } from "../types";
import { MatchTeamMembersResponse } from "./api";

export function reportsData(utcYear:number, utcMonth:number, utcAmdate:number, utcAmhour:number, utcAmminute:number, utcPmdate:number, utcPmhour:number, userId:string, organizationId:string){
    return(
    
        {
            reports: [
            {
                isActive: true,
                name: 'Morning Daily Standup Report',
                sections: [
                {
                    name: 'Pull Requests Pending Review',
                    resource: 'PULL_REQUEST',
                    sectionFilters: [
                        {
                            attribute: 'status',
                            metric: 'VALUE',
                            operator: 'EQUAL',
                            threshold: 'open'
                        },
                        {
                            attribute: 'reviews',
                            metric: 'COUNT',
                            operator: 'LESSER',
                            threshold: '1'
                        },
                        {	
                            attribute: 'openedAt',
                            component: 'PULL_REQUEST',
                            metric: 'TIME',
                            operator: 'ELAPSED',
                            threshold: '5 HOURS',
                        }
                    ]
                },
                {
                    name: 'Issues Expected To Be Completed Today',
                    resource: 'PROJECT_ISSUE',
                    sectionFilters: [
                        {
                            attribute: 'status',
                            metric: 'VALUE',
                            operator: 'EQUAL',
                            threshold: 'In Progress'
                        },
                        {
                            attribute: 'estimatedDueDate',
                            metric: 'TIME',
                            operator: 'RANGE',
                            threshold: 'TODAY'
                        },
                    ]
                },
                {
                    name: 'Issues At Risk',
                    resource: 'PROJECT_ISSUE',
                    sectionFilters: [
                        {
                            attribute: 'status',
                            metric: 'VALUE',
                            operator: 'NOT_EQUAL',
                            threshold: 'Done'
                        },
                        {
                            attribute: 'status',
                            metric: 'VALUE',
                            operator: 'EQUAL',
                            threshold: 'In Progress'
                        },
                        {
                            attribute: 'estimatedDueDate',
                            metric: 'TIME',
                            operator: 'ELAPSED',
                            threshold: '0 HOURS'
                        },
                    ]
                },
                {
                    name: 'High Priority Open Bugs',
                    resource: 'PROJECT_ISSUE',
                    sectionFilters: [
                        {
                            attribute: 'status',
                            metric: 'VALUE',
                            operator: 'NOT_EQUAL',
                            threshold: 'Done'
                        },
                        {
                            attribute: 'priority',
                            metric: 'VALUE',
                            operator: 'EQUAL',
                            threshold: 'HIGHEST'
                        },
                        {
                            attribute: 'type',
                            metric: 'VALUE',
                            operator: 'EQUAL',
                            threshold: 'Bug'
                        },
                    ]
                }
                ],
                schedule: {
                day: `${utcMonth}/${utcAmdate}/${utcYear}`,
                hour: `${utcAmhour}`
                }
            },
            {
                isActive: true,
                name: 'Evening Daily Summary Report',
                sections: [
                {
                    name: 'Pull Requests Merged Today',
                    resource: 'PULL_REQUEST',
                    sectionFilters: [
                        {
                            attribute: 'status',
                            metric: 'VALUE',
                            operator: 'EQUAL',
                            threshold: 'closed'
                        },
                        {
                            attribute: 'mergedAt',
                            metric: 'TIME',
                            operator: 'RANGE',
                            threshold: '-24 HOURS'
                        },
                    ]
                },
                {
                    name: 'Issues Completed Today',
                    resource: 'PROJECT_ISSUE',
                    sectionFilters: [
                        {
                            attribute: 'status',
                            metric: 'VALUE',
                            operator: 'EQUAL',
                            threshold: 'Done'
                        },
                        {
                            attribute: 'issueResolvedAt',
                            metric: 'TIME',
                            operator: 'RANGE',
                            threshold: '-24 HOURS'
                        },
                    ]
                }
                ],
                schedule: {
                day: `${utcMonth}/${utcPmdate}/${utcYear}`,
                hour: `${utcPmhour}`
                }
            },
            {
                isActive: true,
                name: 'Weekly Summary',
                sections: [
                    {
                        name: 'Resource Allocation',
                        resource: 'CHART',
                    },
                    {
                        name: 'Burndown',
                        resource: 'CHART',
                    },
                    {
                        name: 'Unplanned Work',
                        resource: 'UNPLANNED_WORK',   
                    },
                    {
                        name: 'Team Metrics',
                        resource: 'METRIC',   
                    },
                    {
                        name: 'Weekly Goals',
                        resource: 'GOAL',   
                    },
                ],
                schedule: {
                    day: 'Friday',
                    hour: `${utcPmhour}`
                }
            }
            
            ],
            isOnboarding: true,
            organizationId: organizationId,
            userId: userId
        }
    );
}

export function alertsData(
    bugPriority:string,
    tickets:number,
    hours:number,
    hours1:number,
    comments:number,
): Record<string, Alert[]>{
  return(
    {
        jira: [
            {
                activateBy: 'CRON',
                component: 'PROJECT_ISSUE',
                isActive: true,
                name: 'Issue In Progress Past Due Date',
                // message: 'Notify me when there are pull request that have been approved but not merged within 5 hours',
                resource: AlertResourceEnum.BOARD,
                resourceId: '',
                triggers:[
                    {
                    phase: 0,
                    
                    conditions: [
                        {	
                        attribute: 'status',
                        component: 'PROJECT_ISSUE',
                        metric: 'VALUE',
                        operator: ConditionOperatorEnum.EQUAL,
                        threshold: 'In Progress',
                        },
                        {	
                        attribute: 'estimatedDueDate',
                        component: 'PROJECT_ISSUE',
                        metric: 'TIME',
                        operator: ConditionOperatorEnum.ELAPSED,
                        threshold: '10 HOURS',
                        }
                    ],
                    triggerEscalations:[
                        {
                        escalateTo: 'MANAGER',
                        }
                    ],
                    }
                ]
            },
        {
            activateBy: 'CRON',
            component: 'PROJECT_ISSUE',
            isActive: true,
            name: 'P0 Bug Exceeding SLA',
            // message: 'Notify me when there are pull request that have been approved but not merged within 5 hours',
            resource: AlertResourceEnum.BOARD,
            resourceId: '',
            triggers:[
                {
                phase: 0,
                
                conditions: [
                    {	
                    attribute: 'status',
                    component: 'PROJECT_ISSUE',
                    metric: 'VALUE',
                    operator: ConditionOperatorEnum.NOT_EQUAL,
                    threshold: 'Done',
                    },
                    {	
                    attribute: 'priority',
                    component: 'PROJECT_ISSUE',
                    metric: 'VALUE',
                    operator: ConditionOperatorEnum.EQUAL,
                    threshold: `${bugPriority}`,
                    },
                    {	
                    attribute: 'estimatedDueDate',
                    component: 'PROJECT_ISSUE',
                    metric: 'TIME',
                    operator: ConditionOperatorEnum.ELAPSED,
                    threshold: '5 HOURS',
                    },
                    {	
                    attribute: 'type',
                    component: 'PROJECT_ISSUE',
                    metric: 'VALUE',
                    operator: ConditionOperatorEnum.EQUAL,
                    threshold: 'Bug',
                    }
                ],
                triggerEscalations:[
                    {
                    escalateTo: 'MANAGER',
                    }
                ],
                }
            ]
        },
        {
            activateBy: 'EVENT',
            component: 'PROJECT_ISSUE',
            isActive: true,
            name: 'Issue Staus Set To Blocked',
            // message: 'Notify me when there are pull request that have been approved but not merged within 5 hours',
            resource: AlertResourceEnum.BOARD,
            resourceId: '',
            triggers:[
                {
                phase: 0,
                
                conditions: [
                    {	
                    attribute: 'status',
                    component: 'PROJECT_ISSUE',
                    metric: 'VALUE',
                    operator: ConditionOperatorEnum.EQUAL,
                    threshold: 'Blocked',
                    },
                ],
                triggerEscalations:[
                    {
                    escalateTo: 'MANAGER',
                    }
                ],
                }
            ]
        },
        {
            activateBy: 'CRON',
            component: 'PROJECT_ISSUE',
            isActive: true,
            name: 'Issue Trending With 10 Comments',
            // message: 'Notify me when there are pull request that have been approved but not merged within 5 hours',
            resource: AlertResourceEnum.BOARD,
            resourceId: '',
            triggers:[
                {
                phase: 0,
                
                conditions: [
                    {	
                    attribute: 'status',
                    component: 'PROJECT_ISSUE',
                    metric: 'VALUE',
                    operator: ConditionOperatorEnum.EQUAL,
                    threshold: 'In Progress',
                    },
                    {	
                    attribute: 'comments',
                    component: 'PROJECT_ISSUE',
                    metric: 'COUNT',
                    operator: ConditionOperatorEnum.GREATER,
                    threshold: '10',
                    }
                ],
                triggerEscalations:[
                    {
                    escalateTo: 'MANAGER',
                    }
                ],
                }
            ]
        },
        {
            activateBy: 'EVENT',
            component: 'CONTRIBUTOR',
            isActive: true,
            name: 'Contributor Has More Than 5 Issues In Progress',
            // message: 'Notify me when there are pull request that have been approved but not merged within 5 hours',
            resource: AlertResourceEnum.BOARD,
            resourceId: '',
            triggers:[
                {
                phase: 0,
                
                conditions: [
                ],
                triggerEscalations:[
                    {
                    escalateTo: 'MANAGER',
                    }
                ],
                }
            ]
        },
      ],
      github: [
        {
          activateBy: 'EVENT',
          component: 'PULL_REQUEST',
          isActive: true,
          name: 'Large Pull Request Merged Without Comments',
          // message: 'Notify me when large pull requests have been merged without comments',
          resource: AlertResourceEnum.REPOSITORY,
          resourceId: '',
          triggers:[
            {
              phase: 0,
              conditions: [
                {	
                  attribute: 'status',
                  component: 'PULL_REQUEST',
                  metric: 'VALUE',
                  threshold: 'closed',
                  operator: ConditionOperatorEnum.EQUAL
                },
                {	
                  attribute: 'comments',
                  component: 'PULL_REQUEST',
                  metric: 'COUNT',
                  threshold: '1',
                  operator: ConditionOperatorEnum.LESSER
                },
                {	
                  attribute: 'mergedAt',
                  component: 'PULL_REQUEST',
                  metric: 'TIME',
                  operator: ConditionOperatorEnum.RANGE,
                  threshold: '24 HOURS'
                }
              ],
              triggerEscalations:[
                {
                  escalateTo: 'MANAGER',
                }
              ]
            }
          ]
        },
        {
          activateBy: 'CRON',
          component: 'PULL_REQUEST',
          isActive: true,
          name: 'Pull Request Pending Review',
          // message: 'Notify me when there are pull requests who have been pending review for more than 5 hours',
          resource: AlertResourceEnum.REPOSITORY,
          resourceId: '',
          triggers:[
            {
              phase: 0,
              conditions: [
                {	
                  attribute: 'status',
                  component: 'PULL_REQUEST',
                  metric: 'VALUE',
                  operator: ConditionOperatorEnum.EQUAL,
                  threshold: 'open',
                },
                {	
                  attribute: 'reviews',
                  component: 'PULL_REQUEST',
                  metric: 'COUNT',
                  operator: ConditionOperatorEnum.LESSER,
                  threshold: '1',
                },
                {	
                  attribute: 'openedAt',
                  component: 'PULL_REQUEST',
                  metric: 'TIME',
                  operator: ConditionOperatorEnum.ELAPSED,
                  threshold: `${hours} HOURS`,
                }
              ],
              triggerEscalations:[
                {
                  escalateTo: 'REVIEWER',
                }
              ],
            }
          ]
        },
        {
          activateBy: 'CRON',
          component: 'PULL_REQUEST',
          isActive: true,
          name: 'Pull Request Approved Pending Merge',
          // message: 'Notify me when there are pull request that have been approved but not merged within 5 hours',
          resource: AlertResourceEnum.REPOSITORY,
          resourceId: '',
          triggers:[
            {
              phase: 0,
             
              conditions: [
                {	
                  attribute: 'status',
                  component: 'PULL_REQUEST',
                  metric: 'VALUE',
                  operator: ConditionOperatorEnum.EQUAL,
                  threshold: 'open',
                },
                {	
                  attribute: 'isApproved',
                  component: 'PULL_REQUEST',
                  metric: 'VALUE',
                  operator: ConditionOperatorEnum.EQUAL,
                  threshold: 'true',
                },
                {	
                  attribute: 'approvedAt',
                  component: 'PULL_REQUEST',
                  metric: 'TIME',
                  operator: ConditionOperatorEnum.ELAPSED,
                  threshold: `${hours1} HOURS`,
                }
              ],
              triggerEscalations:[
                {
                  escalateTo: 'MANAGER',
                }
              ],
            }
          ]
        },
        {
          activateBy: 'CRON',
          component: 'PULL_REQUEST',
          isActive: true,
          name: 'Pull Request Trending With Over Five Comments',
          // message: 'Notify me when there are pull request that have been approved but not merged within 5 hours',
          resource: AlertResourceEnum.REPOSITORY,
          resourceId: '',
          triggers:[
            {
              phase: 0,
             
              conditions: [
                {	
                  attribute: 'status',
                  component: 'PULL_REQUEST',
                  metric: 'VALUE',
                  operator: ConditionOperatorEnum.EQUAL,
                  threshold: 'open',
                },
                {	
                  attribute: 'isApproved',
                  component: 'PULL_REQUEST',
                  metric: 'VALUE',
                  operator: ConditionOperatorEnum.EQUAL,
                  threshold: 'false',
                },
                {	
                  attribute: 'comments',
                  component: 'PULL_REQUEST',
                  metric: 'COUNT',
                  operator: ConditionOperatorEnum.GREATER,
                  threshold: `${comments}`,
                }
              ],
              triggerEscalations:[
                {
                  escalateTo: 'MANAGER',
                }
              ],
            }
          ]
        },
      ],
    }
  );
}


export function personalData(): MatchTeamMembersResponse{
  return(
    {
    "atlassianTeamMembers": [
        {
            "active": true,
            "id": "712020:9fd22e4e-633a-4da4-94db-648c20fa4ae6",
            "email": "",
            "name": "James",
            "link": "https://api.atlassian.com/ex/jira/2685ec34-f005-4bcf-bbce-b4b13f8620e4/rest/api/2/user?accountId=712020:9fd22e4e-633a-4da4-94db-648c20fa4ae6",
            "username": "James"
        },
        {
            "active": true,
            "id": "712020:4dded0aa-410d-4abf-bc0b-468a21ad3a28",
            "email": "copilot.ic@gmail.com",
            "name": "John Doe",
            "link": "https://api.atlassian.com/ex/jira/2685ec34-f005-4bcf-bbce-b4b13f8620e4/rest/api/2/user?accountId=712020:4dded0aa-410d-4abf-bc0b-468a21ad3a28",
            "username": "John Doe"
        },
        {
            "active": true,
            "id": "557058:eed612a8-f8be-47df-92c0-87db49f54f80",
            "email": "royihaddad@gmail.com",
            "name": "Royi Haddad",
            "link": "https://api.atlassian.com/ex/jira/2685ec34-f005-4bcf-bbce-b4b13f8620e4/rest/api/2/user?accountId=557058:eed612a8-f8be-47df-92c0-87db49f54f80",
            "username": "Royi Haddad"
        },
        {
            "active": true,
            "id": "5cfa7cd8c756c10f35f2b137",
            "email": "",
            "name": "Shani Shoham",
            "link": "https://api.atlassian.com/ex/jira/2685ec34-f005-4bcf-bbce-b4b13f8620e4/rest/api/2/user?accountId=5cfa7cd8c756c10f35f2b137",
            "username": "Shani Shoham"
        }
    ],
    "count": 0,
    "githubTeamMembers": [
        {
            "id": 9021094,
            "link": "https://api.github.com/users/RoyiHD",
            "username": "RoyiHD"
        },
        {
            "id": 52222713,
            "link": "https://api.github.com/users/shanishoham1",
            "username": "shanishoham1"
        },
        {
            "id": 69329614,
            "link": "https://api.github.com/users/twinik",
            "username": "twinik"
        },
        {
            "id": 173197911,
            "link": "https://api.github.com/users/Crown160265",
            "username": "Crown160265"
        },
        {
            "id": 9021094,
            "link": "https://api.github.com/users/RoyiHD",
            "username": "Jesus"
        },
        {
            "id": 9021094,
            "link": "https://api.github.com/users/RoyiHD",
            "username": "God"
        },
        {
            "id": 9021094,
            "link": "https://api.github.com/users/RoyiHD",
            "username": "JamesDoe"
        },
    ],
    "matchedTeamMembers": [
        {
            "atlassianUsername": "Royi Haddad",
            "atlassianUserId": "557058:eed612a8-f8be-47df-92c0-87db49f54f80",
            "atlassianUserLink": "https://api.atlassian.com/ex/jira/2685ec34-f005-4bcf-bbce-b4b13f8620e4/rest/api/2/user?accountId=557058:eed612a8-f8be-47df-92c0-87db49f54f80",
            "email": "royihaddad@gmail.com",
            //"githubUsername": "RoyiHD",
            "githubUserLink": "https://api.github.com/users/RoyiHD",
            "githubUserId": 9021094,
            "name": "Royi Haddad",
            "score": 0.4,
            "role":"Manager",
        },
        {
            "atlassianUsername": "Shani Shoham",
            "atlassianUserId": "5cfa7cd8c756c10f35f2b137",
            "atlassianUserLink": "https://api.atlassian.com/ex/jira/2685ec34-f005-4bcf-bbce-b4b13f8620e4/rest/api/2/user?accountId=5cfa7cd8c756c10f35f2b137",
            "email": "",
            //"githubUsername": "shanishoham1",
            "githubUserLink": "https://api.github.com/users/shanishoham1",
            "githubUserId": 52222713,
            "name": "Shani Shoham",
            "score": 0.8181818181818182,
            "role":"Manager",
        },
        {
            "atlassianUsername": "John Doe",
            "atlassianUserId": "712020:4dded0aa-410d-4abf-bc0b-468a21ad3a28",
            "atlassianUserLink": "https://api.atlassian.com/ex/jira/2685ec34-f005-4bcf-bbce-b4b13f8620e4/rest/api/2/user?accountId=712020:4dded0aa-410d-4abf-bc0b-468a21ad3a28",
            "email": "copilot.ic@gmail.com",
            "githubUsername": undefined,
            "githubUserLink": "https://api.github.com/users/twinik",
            "githubUserId": 69329614,
            "name": "John Doe",
            "score": 0,
            "role":"Manager",
        },
        {
            "atlassianUsername": "James",
            "atlassianUserId": "712020:9fd22e4e-633a-4da4-94db-648c20fa4ae6",
            "atlassianUserLink": "https://api.atlassian.com/ex/jira/2685ec34-f005-4bcf-bbce-b4b13f8620e4/rest/api/2/user?accountId=712020:9fd22e4e-633a-4da4-94db-648c20fa4ae6",
            "email": "",
            "githubUsername": undefined,//,
            "githubUserLink": "https://api.github.com/users/Crown160265",
            "githubUserId": 173197911,
            "name": "James",
            "score": 0,
            "role":"Manager",
        }
    ]
}
  );
}