// src/utils/auth.js
import Cookies from 'js-cookie';

export const setToken = (token: string) => {
  // Store the JWT in a cookie
  Cookies.set('token', token, { expires: 1 }); // Expires in 1 day
};

export const getToken = (): string | undefined => {
  return Cookies.get('token');
};

export const removeToken = (): void => {
  Cookies.remove('token');
};