import React, { createContext, useContext, useEffect, useState } from 'react';
import { User } from '../default/types';

interface UserContextType {
    user: User | null;
    setUserInformation: (userData: User) => void;
    removeUserInformation: () => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUser = (): UserContextType => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {

        const userId = localStorage.getItem('userId');
        const organizationId = localStorage.getItem('organizationId');
        const firstName = localStorage.getItem("firstName");
        const lastName = localStorage.getItem("lastName");

        if (userId && organizationId && firstName && lastName) {
            console.log('User Context useEffect Called. Setting User')
            setUser({
                id: userId,
                organizationId: organizationId,
                firstName: firstName ,
                lastName: lastName,
            });
        }
    }, []);

    const setUserInformation = (userData: User): void => {
        setUser(userData);
    };

    const removeUserInformation = (): void => {
        localStorage.removeItem("organizationId");
        localStorage.removeItem("lastName");
        localStorage.removeItem("firstName");
        localStorage.removeItem("userId");
    }
  return (
    <UserContext.Provider value={{ user, setUserInformation, removeUserInformation }}>
        {children}
    </UserContext.Provider>
  );
};
